import React, { useContext } from 'react';
import { filter } from 'lodash';
import Select from "react-select";
import { IndexData } from "../../common/index-context";
import { OpenEndContext } from '../../common/openEndContext';
import { vccPageEvent } from '../../../../common/pageEventHelper';

const FiltersDropdown = ({ options }) => {
  const { selectedOptions, setSelectedOptions, selectedBsg, loadData, data, init } = useContext(IndexData);
  const { setChallengesSummary } = useContext(OpenEndContext);
  const exists = (dd) => filter(dd, (item) => {
    return item.value === 0;
  });

  const cleared = (options) => {
    return !exists(options).length;
  };

  const onApplyFilter = (selectData) => {
    const filters = { selected_bsg: selectedBsg, selected_sub_groups: selectData };
    loadData(filters, null, null, null, { fromUrl: true }, (gData) => {
      setChallengesSummary(gData?.challenges?.summary);
    });
  };

  const handleSelect = (selectData, selected) => {
    setSelectedOptions(selectData);
    onApplyFilter(selectData);
    if (selected.option && init.verizon_roles.length) {
      vccPageEvent(`filter_${selected.option.value}`, 'scorecard', [ data.case_id ]);
    }
  };

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      options={ options }
      placeholder="Select filter"
      value={ selectedOptions }
      onChange={ handleSelect }
      isSearchable
      isMulti
      isClearable={ cleared(selectedOptions) }
    />
  );
};

export default FiltersDropdown;
