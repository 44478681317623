import React, { createContext, useState, useEffect, useRef } from 'react';
import { find, each, map } from 'lodash';
import { useLocation } from 'react-router-dom';
import Http from "../../../common/http";
import { sendMessage } from "../../../common/messaging";
import TourApp from '../tour/tour_app';
import { initializeGoogleAnalytics } from "../../common/google-analytics/googleAnalyticsTracking";
import { vccPageEvent } from '../../../common/pageEventHelper';
import { hashToId } from "./hash-to-id";
import downloadStarts from "./download-messaging";

const IndexData = createContext({});

const selectGroup = (data, group, hidden = false) => {
  const result = {};
  each(data.analytics, (value, key) => {
    if (value.group_name === group && (!!value.hidden === hidden)) {
      result[key] = value;
    }
  });
  return result;
};

const selectKind = (data, kind) => {
  const result = {};
  each(data.analytics, (value, key) => {
    if (value.kind === kind) {
      result[key] = value;
    }
  });
  return result;
};

const getMetricKind = (data, source) => {
  return data.translations.metric_kinds[source] || source;
};

const getMetricTitle = (data, source) => {
  return data.translations.metrics[source] || source;
};

const WithIndexContext = ({ init, userGetConceptId, children }) => {
  const [ concepts, setConcepts ] = useState([]);
  const [ dir, setDir ] = useState('');
  const [ sort, setSort ] = useState('');
  const [ current, setCurrent ] = useState(null);
  const [ comments, setComments ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ data, setData ] = useState({});
  const [ toolId, setToolId ] = useState(null);
  const [ showEntityModal, setShowEntityModal ] = useState(false);
  const [ averageMode, setAverageMode ] = useState({});
  const [ reportView, setReportView ] = useState({});
  const [ statTestingVal, setStatTestingVal ] = useState('');
  const [ showTurf, setShowTurf ] = useState(false);
  const [ turfData, setTurfData ] = useState({});
  const [ turfLoadingError, setTurfLoadingError ] = useState(false);
  const [ showOverviewModal, setShowOverviewModal ] = useState(false);
  const [ showInternalViewWarningModal, setInternalViewWarningModal ] = useState(false);
  const [ selectedOptions, setSelectedOptions ] = useState([]);
  const [ selectedBsg, setSelectedBsg ] = useState([]);
  const [ customizedQuestionData, setCustomizedQuestionData ] = useState(null);
  const [ aiSummaryAvailable, setAiSummaryAvailable ] = useState(false);
  const [ aiSummaries, setAiSummaries ] = useState(null);
  const [ aiSummariesPlacesCount, setAiSummariesPlacesCount ] = useState(0);

  useEffect(() => {
    initializeGoogleAnalytics(init.google_analytics_id);
  }, [ ]);

  // Font Selection Modal
  const [ fontSelectionModalData, setFontSelectionModalData ] = useState(null);
  const onFontSelectionModalClose = () => {
    setFontSelectionModalData(null);
  };
  const openFontPreselectionModal = (event, exportFunction, params) => {
    event.preventDefault();
    setFontSelectionModalData({ exportFunction, params });
  };

  // Brand Comparison Modal
  const [ brandComparisonModalData, setBrandComparisonModalData ] = useState(null);
  const onBrandComparisonModalClose = () => {
    setBrandComparisonModalData(null);
  };
  const openBrandComparisonModal = (event, exportFunction, params) => {
    event.preventDefault();
    setBrandComparisonModalData({ exportFunction, params });
  };

  const setCurrentItem = (currentItem, kind = 'concept') => {
    setCurrent({ ...currentItem, ...{ kind } });
  };

  const setCurrentWithUrl = (concept, kind = 'concept') => {
    window.history.replaceState(null, '', concept.report_path);
    setCurrentItem(concept, kind);
  };

  const setCurrentConceptWithUrl = (concept) => {
    setCurrentWithUrl(concept, 'concept');
  };

  const setCurrentClaimWithUrl = (claim) => {
    setCurrentWithUrl(claim, 'claim');
  };

  const setCurrentConceptFromData = (data) => {
    const freshConcept = find(data.concepts, (el) => (el.id === current.id));
    setCurrentItem(freshConcept, 'concept');
  };

  const setCurrentClaimFromData = (data) => {
    const freshClaim = find(data.analytics, (el) => (el.id === current.id));
    setCurrentItem(freshClaim, 'claim');
  };

  const setCurrentItemFromDatabasedOnCurrentKind = (data) => {
    if (current) {
      current.kind === 'concept' ?
        setCurrentConceptFromData(data) :
        setCurrentClaimFromData(data);
    }
  };

  // Tour data
  const tourData = init.project.tour_data;
  const tourRedirectPath = init.project.tour_redirect_path;
  const tourExpressKind = init.project.express_kind;
  const isTurf = init.project.is_turf;
  const isMaxDiff = init.project.max_diff;
  const [ tourElements, setTourElements ] = useState({});
  const [ forceOpenFilterDropDown, setForceOpenFilterDropDown ] = useState(false);
  const [ forceOpenButtons, setForceOpenButtons ] = useState({});
  const [ forceCFTab, setForceCFTab ] = useState(null);
  const [ forceCFMarkupTab, setForceMarkupCFTab ] = useState(null);
  const [ customTourFunctions, setCustomTourFunctions ] = useState({});
  const customTourFunctionsRef = useRef();
  customTourFunctionsRef.current = customTourFunctions;

  const setForceOpenButton = (name, open) => {
    setForceOpenButtons((oldHash) => (
      { ...oldHash, ...{ [name]: open } }
    ));
  };
  const getForceOpenButtonState = (name) => (forceOpenButtons[name] || false);
  const getTourUseEffectArgs = (name, element) => (
    [
      () => {
        if (!tourData || !name) {
          return () => {};
        }
        setTourElements((oldHash) => (
          { ...oldHash, ...{ [name]: element.current } }
        ));
        return () => {
          setTourElements((oldHash) => {
            delete oldHash[name];
            return { ...oldHash };
          });
        };
      },
      []
    ]
  );
  const tourConcepts = tourData ? tourData.concepts : [];
  const tourComments  = tourData ? tourData.comments : [];
  const tourCFWordClouds = tourData ? tourData.cf_wordclouds : {};
  const tourTexts = tourData ? tourData.tour_texts : [];

  const getConceptId = () => {
    if (userGetConceptId) {
      return userGetConceptId(current);
    }
    return current.id;
  };

  const loadComments = (forTab) => {
    if (forTab !== 'heatmap' && forTab !== 'markups') {
      return;
    }

    if (tourData) {
      if (tourComments) {
        setComments(tourComments);
      }
      return;
    }

    if (!data.cf_enabled) {
      return;
    }

    setLoading(true);

    Http.post(
      init.urls.comments,
      {
        concept_id: getConceptId(),
        tool_id: toolId,
        filter: data.filter
      }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          setComments(http.data.comments);
        }
        setLoading(false);
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const loadOpenEndCard = (question, openEndCardField, openEndCard) => {
    setLoading(true);
    const refreshUrl = openEndCard?.metric?.urls?.refresh;
    if (!refreshUrl) {
      return;
    }

    Http.post(
      refreshUrl,
      {
        source_type: openEndCard.metric.source_type,
        source_id: openEndCard.metric.source_id,
        filter: data.filter.demographics
      }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          question[openEndCardField] = http.data;
        }
        setLoading(false);
      },
      (reject) => {
        console.log('something went wrong', reject);
        setLoading(false);
      }
    );
  };

  const loadCurrent = () => {
    loadData(data.filter, null, null, null, {}, (data) => {
      setCurrentConceptFromData(data);
    });
  };

  const loadClaim = () => {
    loadData(data.filter, null, null, null, {}, (data) => {
      setCurrentClaimFromData(data);
    });
  };

  const loadData = (
    filters, ldSettings, sorting, statTesting, options = {}, callback
  ) => {
    const { combinations, averageMode, clients, view, reportView, fromUrl } = options;
    if (tourData) {
      setData(tourData);
      setConcepts(tourConcepts);
      if (callback) {
        callback(tourData);
      }
      return;
    }

    setLoading(true);

    const params = {};

    if (filters) {
      params['filter'] = filters;
    }
    if (ldSettings) {
      params['settings'] = ldSettings;
    }
    if (sorting) {
      params['sorting'] = sorting;
    }
    if (statTesting) {
      params['stat_testing'] = statTesting;
    }
    if (combinations) {
      params['combinations'] = combinations;
    }
    if (averageMode) {
      params['average_mode'] = averageMode;
    }
    if (clients) {
      params['clients'] = clients;
    }
    if (view) {
      params['view'] = view;
    }
    if (reportView) {
      params['report_view'] = reportView;
    }

    if (fromUrl) {
      params['from_url'] = true;
    }

    Http.post(
      init.urls.api,
      params
    ).then(
      (http) => {
        if (http.response.status === 200) {
          setData(http.data);
          if (http.data.sorting) {
            setDir(http.data.sorting.dir);
            setSort(http.data.sorting.sort);
          }
          if (http.data.concepts) {
            setConcepts(http.data.concepts);
          }
          if (http.data.average_mode) {
            setAverageMode(http.data.average_mode);
          }
          if (http.data.report_view) {
            setReportView(http.data.report_view);
          }
          if (http.data.stat_testing) {
            setStatTestingVal(http.data.stat_testing);
          }
          setAiSummaryAvailable(http.data.ai_summary_available);
          if (http.data.ai_summaries) {
            setAiSummaries(http.data.ai_summaries);
            setAiSummariesPlacesCount(http.data.ai_summaries_places_count);
          }
          setCurrentItemFromDatabasedOnCurrentKind(http.data);
          if (callback) {
            callback(http.data);
          }
        }
        setLoading(false);
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const loadTurfData = (id, filters, sorting, statTesting, combinations) => {
    if (tourData) {
      setTurfData(tourData.turf_data);
      setTurfLoadingError(false);
      return;
    }

    setLoading(true);

    const params = {};

    if (filters) {
      params['filter'] = filters;
    }
    if (sorting) {
      params['sorting'] = sorting;
    }
    if (statTesting) {
      params['stat_testing'] = statTesting;
    }
    if (combinations) {
      params['combinations'] = combinations;
    }

    const runBadResponse = (response) => {
      console.log('something went wrong', response);
      setTurfData({});
      setTurfLoadingError(true);
      setLoading(false);
    };

    Http.post(
      `/express/api/projects/${id}/concept_comparisons/turf_data.json`,
      params
    ).then(
      (response) => {
        if (response.response.ok) {
          setTurfData(response.data);
          setTurfLoadingError(false);
        } else {
          runBadResponse(response);
        }
        setLoading(false);
      },
      (reject) => {
        runBadResponse(reject);
      }
    );
  };

  const onClientsUpdate = (newValue) => {
    loadData(null, null, null, null, { clients: newValue });
  };

  const clientList = () => {
    const list = map(data.clients, (client) => {
      return { value: client.id, label: client.name };
    });
    list.push({ value: 0, label: "All Clients" });
    return list;
  };

  const openOverviewModal = () => {
    window.history.replaceState(null, '', `${window.location.pathname}#!?overview`);
    setShowOverviewModal(true);
  };

  const closeOverviewModal = () => {
    window.history.replaceState(null, '', init.urls.base);
    setShowOverviewModal(false);
  };

  const openEntityModal = (data, concept) => {
    if (!data || !concept) {
      return;
    }
    closeSwitchModal();
    setCurrentConceptWithUrl(concept);
    setToolId(data.settings[concept.id].tool_id);
    setShowEntityModal(true);
    sendMessage({ to: "Angular", kind: "scorecardOpened", id: concept.id });
  };

  const openClaimModal = (data, claim) => {
    if (!data || !claim) {
      return;
    }
    closeSwitchModal();
    setCurrentClaimWithUrl(claim);
    setToolId(data.tools[0].id);
    setShowEntityModal(true);
    sendMessage({ to: "Angular", kind: "scorecardOpened", id: claim.id });
  };

  const openTourModal = (claim) => {
    claim ?
      openClaimModal(tourData, tourData.analytics[0]) :
      openEntityModal(tourData, tourData.concepts[0]);
  };

  const closeEntityModal = () => {
    window.history.replaceState(null, '', init.urls.base);
    setShowEntityModal(false);
    sendMessage({ to: "Angular", kind: "scorecardClosed" });
  };

  const closeTourModal = () => {
    closeEntityModal();
  };

  const openTurfModal = (onlySetState = false) => {
    setShowTurf(true);
    if (onlySetState) {
      return;
    }
    sendMessage({ to: "Angular", kind: "scorecardClosed" });
    window.history.pushState(null, '', `${init.urls.base}#!?turf`);
  };

  const closeTurfModal = (onlySetState = false) => {
    setShowTurf(false);
    if (onlySetState) {
      return;
    }
    sendMessage({ to: "Angular", kind: "scorecardClosed" });
    window.history.replaceState(null, '', init.urls.base);
  };

  const overviewOpened = window.location.hash === '#!?overview';

  const openConceptAfterReload = (data) => {
    if (!showOverviewModal && overviewOpened) {
      openOverviewModal();
      return;
    }

    const conceptId = hashToId('concept_id', window.location.hash);
    const conceptToShow = find(data.concepts, (el) => (el.id === conceptId));
    if (!showEntityModal && conceptToShow) {
      openEntityModal(data, conceptToShow);
    }
  };

  const openClaimAfterReload = (data) => {
    const claimId = hashToId('claim_id', window.location.hash);
    const claimToShow = find(data.analytics, (el) => (el.id === claimId));
    if (!showEntityModal && claimToShow) {
      openClaimModal(data, claimToShow);
    }
  };

  const settings = current ? (data.settings[current.id] || {}) : {};
  const selectedTool = find(data.tools, (el) => {
    return el.id === settings.tool_id;
  });

  const [ selected, setSelected ] = useState([]);
  const [ wcLoading, setWcLoading ] = useState(false);
  const loadWords = () => {
    if (tourData) {
      setSelected(tourCFWordClouds);
      return;
    }

    setWcLoading(true);

    const url = `/dashboard/api/open_ends/concepts/${getConceptId()}`;
    Http.post(
      url, { filter: data.filter.demographics }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          setSelected(http.data.selected);
        }
        setWcLoading(false);
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const [ showSwitchModal, setShowSwitchModal ] = useState(false);
  const openSwitchModal = () => {
    setShowSwitchModal(true);
  };

  const closeSwitchModal = () => {
    setShowSwitchModal(false);
  };

  const [ exportData, setExportData ] = useState(null);
  const onInternalViewWarningModalClose = () => {
    setExportData(null);
  };
  const openInternalViewWarningModal = (event, exportFunction) => {
    event.preventDefault();
    setExportData({ exportFunction });
  };
  const conceptById = (id) => {
    return find(data.concepts, (c) => parseInt(c.id) === parseInt(id)) || {};
  };

  const claimById = (id) => {
    return find(data.claims, (c) => parseInt(c.id) === parseInt(id)) || {};
  };

  const selectedFocusConcept = (tool) => {
    if (tool) {
      return tool.concepts[getConceptId()];
    }
    return {};
  };

  const reportUrl = (conceptId, claimId, name, kind) => {
    let temp = `${init.urls.export}?report=${name}&kind=${kind}`;
    if (conceptId) {
      temp = `${temp}&concept_id=${conceptId}`;
    }
    if (claimId) {
      temp = `${temp}&claim_id=${claimId}`;
    }
    return temp;
  };

  const exportReport = (event, conceptId, claimId, item, kind, params = {}, skipWarning = false) => {
    event.preventDefault();
    event.stopPropagation();
    const { name, customFontSize, showComparisonOptions } = item;

    const url = reportUrl(conceptId, claimId, name, kind);
    const postParams = { ...params, ...{ current_all_as_hash: data } };

    const exportFunction = (functionEvent, params, callback) => {
      Http.post(url, params).then(
        (http) => {
          if (http.response.status === 200) {
            downloadStarts(functionEvent, init.i18n.download);
          }
          callback && callback(http);
        },
        (reject) => {
          console.log('something went wrong', reject);
          callback && callback(reject);
        }
      );

      if (item.pageEvent && init.verizon_roles.length) {
        vccPageEvent(
          item.pageEvent.kind,
          item.pageEvent.page,
          item.pageEvent.caseIds,
          item.pageEvent.downloadKind || params.format_title
        );
      }
    };

    const showFontPreselectionModal = customFontSize && kind === 'pptx';
    const showBrandComparisonModal = showComparisonOptions && kind === 'pptx';

    const mainExportFunction = () => {
      if (showFontPreselectionModal) {
        openFontPreselectionModal(event, exportFunction, postParams);
      } else if (showBrandComparisonModal) {
        openBrandComparisonModal(event, exportFunction, postParams);
      } else {
        exportFunction(event, postParams);
      }
    };

    if (init.isInternal && data.view === "internal" && averageMode === "platform" && !skipWarning) {
      openInternalViewWarningModal(event, mainExportFunction);
    } else {
      mainExportFunction();
    }
  };


  const { wordCloudColors, wordCloudFont } = init;

  const dd = {
    data,
    init,
    concepts,
    setConcepts,
    dir,
    sort,
    setDir,
    setSort,
    loadData,
    loadComments,
    wcLoading,
    loadCurrent,
    loadClaim,
    loadOpenEndCard,
    loadWords,
    openEntityModal,
    closeEntityModal,
    current,
    setCurrentConceptWithUrl,
    setCurrentClaimWithUrl,
    comments,
    loading,
    showEntityModal,
    openClaimModal,
    openConceptAfterReload,
    openClaimAfterReload,
    selected,
    settings,
    selectedTool,
    showSwitchModal,
    openSwitchModal,
    closeSwitchModal,
    conceptById,
    claimById,
    getConceptId,
    selectedFocusConcept,
    averageMode,
    setAverageMode,
    statTestingVal,
    turfIsShown: () => (showTurf),
    openTurfModal,
    closeTurfModal,
    turfData,
    loadTurfData,
    turfLoadingError,
    openOverviewModal,
    closeOverviewModal,
    showOverviewModal,
    setShowOverviewModal,
    wordCloudColors,
    wordCloudFont,
    showInternalViewWarningModal,
    setInternalViewWarningModal,
    onInternalViewWarningModalClose,
    openInternalViewWarningModal,
    selectedOptions,
    setSelectedOptions,
    selectedBsg,
    setSelectedBsg,
    onClientsUpdate,
    clientList,
    exportData,
    customizedQuestionData,
    setCustomizedQuestionData,
    reportView,
    setReportView,
    // Font Selection Modal
    fontSelectionModalData,
    onFontSelectionModalClose,
    openFontPreselectionModal,
    //Brand Comparison Modal
    brandComparisonModalData,
    onBrandComparisonModalClose,
    openBrandComparisonModal,
    // Tour
    tourData,
    getTourElements: () => (tourElements),
    getTourUseEffectArgs,
    getForceOpenFilterDropDown: () => (forceOpenFilterDropDown),
    setForceOpenFilterDropDown,
    setForceOpenButton,
    getForceOpenButtonState,
    openTourModal,
    closeTourModal,
    getForceCFTab: () => (forceCFTab),
    setForceCFTab,
    getForceCFMarkupTab: () => (forceCFMarkupTab),
    setForceMarkupCFTab,
    getCustomTourFunctions: () => (customTourFunctionsRef.current),
    setCustomTourFunctions,
    exportReport,
    allowDatabaseCompare: data?.allow_database_compare,
    aiSummaryAvailable,
    aiSummaries,
    setAiSummaries,
    aiSummariesPlacesCount
  };

  const location = useLocation();
  const currentData = useRef();
  currentData.current = data;

  useEffect(() => {
    const conceptId = hashToId('concept_id', location.hash);
    if (conceptId) {
      const conceptToShow = find(currentData.current.concepts, (el) => (el.id === conceptId));
      openEntityModal(currentData.current, conceptToShow);
      return;
    }

    const claimId = hashToId('claim_id', location.hash);
    if (claimId) {
      const claimToShow = find(currentData.current.analytics, (el) => (el.id === claimId));
      openClaimModal(currentData.current, claimToShow);
      return;
    }
  }, [ location ]);

  useEffect(() => {
    if (current && toolId) {
      loadComments(data.settings[current.id].tab);
    }
  }, [ current, toolId ]);

  return (
    <IndexData.Provider value={ dd }>
      { children }
      {
        tourData &&
        <TourApp
          express={ tourExpressKind }
          redirectPath={ tourRedirectPath }
          texts={ tourTexts }
          isTurf={ isTurf }
          isMaxDiff={ isMaxDiff }
        />
      }
    </IndexData.Provider>
  );
};

export { WithIndexContext, IndexData, selectGroup, selectKind, getMetricKind, getMetricTitle };
