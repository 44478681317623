import React, { Fragment, useContext } from 'react';
import { map } from "lodash";
import { IndexData } from '../contexts';
import { isEmptyV } from '../helpers';

const Column1 = () => {
  const { data } = useContext(IndexData);

  const dd = data.snapshot_view;
  const hideEmpty = (v) => {
    return isEmptyV(v) && data.blank === 'hide';
  };

  return (
    <div className="comparisons_plates-grid-item">
      <div className="comparisons_plate -full-height">
        <div className="comparisons_grid -space-lg">
          <div className="comparisons_grid-item">
            <div className="comparisons_plate-row">
              <div className="texts">
                <div className="title -big -inline">Total</div>
                <div className="thin-title -inline"> (All cases)</div>
              </div>
              <div className="value -big">
                {data.total}
              </div>
            </div>
            <hr />
          </div>
        </div>

        <div className="comparisons_grid -space-md">
          <div className="comparisons_grid-item">
            <Row
              text={ dd.wireless.title }
              value={ dd.wireless.total }
            />
          </div>
          <div className="comparisons_grid-item">
            <Row
              text={ dd.home_internet.title }
              value={ dd.home_internet.total }
            />
          </div>
        </div>

        <hr className="-gray" />

        <div className="comparisons_grid -space-md">
          <div className="comparisons_grid-item">
            {map(dd.wireless.audience, (el, index) => (
              <Fragment key={ index }>
                {!hideEmpty(el.value) &&
                  <Row
                    text={ el.title }
                    note={ el.note }
                    value={ el.value }
                  />}
              </Fragment>
            ))}
          </div>
          <div className="comparisons_grid-item">
            {map(dd.home_internet.audience, (el, index) => (
              <Fragment key={ index }>
                {!hideEmpty(el.value) &&
                  <Row
                    text={ el.title }
                    note={ el.note }
                    value={ el.value }
                  />}
              </Fragment>
            ))}
          </div>
        </div>

        <hr className="-gray" />

        { (!hideEmpty(dd.wireless.targeted_subgroups) || !hideEmpty(dd.home_internet.targeted_subgroups)) &&
          <>
            <div className="comparisons_grid -space-md">
              <div className="comparisons_grid-item">
                <Row
                  text="Targeted Subgroups"
                  value={ dd.wireless.targeted_subgroups }
                />
              </div>
              <div className="comparisons_grid-item">
                <Row
                  text="Targeted Subgroups"
                  value={ dd.home_internet.targeted_subgroups }
                />
              </div>
            </div>
            <hr className="-gray" />
          </>
        }

        <div className="comparisons_plate-note -margin-top -center -sm">
          {data.dark_projects_text}
        </div>

      </div>
    </div>
  );
};

const Row = ({ text, note, value }) => {
  return (
    <div className="comparisons_plate-row">
      <div className="texts">
        <div className="title">{text}</div>
        {!!note && <div className="note -sm -gray">{note}</div>}
      </div>
      <div className="value -tiny">
        {value}
      </div>
    </div>
  );
};

export default Column1;
