import React from "react";
import cn from "classnames";
import { map } from 'lodash';
import SvgIcon from '../../../../../common/components/svg-icon';

const WithData = ({
  summary, handleEditClick, handleOnChange, handleUpdateSummary, disabled, className
}) => {
  return (
    <div className="middle">
      <div className={ cn("summary", className) }>
        <ul>
          {
            map(summary.raw_data, (item, key) => (
              <li key={ item.name }>
                <b>{item.name}:</b>
                {' '}
                { item.long_summary }
              </li>
            ))
          }
        </ul>
      </div>
      <div className="buttons">
        <a className="link" onClick={ handleEditClick }>
          <SvgIcon name="pen-nocolor" />
          Edit Summary
        </a>
        <a className="link" onClick={ handleUpdateSummary }>
          <SvgIcon name="refresh-nocolor" />
          Update Summary (This will delete the current BuzzIQ Summary, please verify before selecting)
        </a>
      </div>
    </div>
  );
};

export default WithData;
