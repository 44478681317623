import React, { useContext } from 'react';
import { IndexData } from '../../common/index-context';
import Checkbox from "../components/checkbox";
import { baseContext } from "../../../respondent/common/contexts";

const Benchmarks = () => {
  const { data, init } = useContext(IndexData);
  const { model, setModel } = useContext(baseContext);
  const benchmarks = data?.benchmarks || {};
  const recommendedAvgNumber = benchmarks.recommended_avg_number || 0;
  const numberOfTests = benchmarks.number_of_tests || 0;
  const recommendationTexts = init.recommendation_texts;

  return (
    <>
      <div className="comparisons_section">
        <h2 className="comparisons_section-title">Methodology Details</h2>
        <Checkbox
          key={ `methodology-report` }
          title="Add to Report"
          value={ model.methodology }
          paramName="methodology"
          onChange={ (v) => { setModel({ ...model, methodology: v }); } }
        />
      </div>
      <div className="comparisons_plates">
        <div className="comparisons_plates-grid">

          <div className="comparisons_plates-grid-item">
            <div className="comparisons_plate -full-height">
              <h3 className="comparisons_plate-title -md -bordered">Benchmarks & Thresholds</h3>
              <div className="comparisons_plate-section">
                <h4 className="comparisons_plate-section-title">Go-Forward Recommendation Rating</h4>
                {
                  !data.arrows_mode &&
                  <FirstColumnSection1
                    n={ recommendedAvgNumber }
                    recommendationTexts={ recommendationTexts }
                  />
                }
                {
                  data.arrows_mode && data.benchmarks_texts &&
                  <FirstColumnSection1A texts={ data.benchmarks_texts } />
                }
              </div>

              <div className="comparisons_plate-section">
                <h4 className="comparisons_plate-section-title">Metrics</h4>
                {!data.arrows_mode && <FirstColumnSection2 n={ numberOfTests } />}
                {data.arrows_mode && data.benchmarks_texts &&
                  <FirstColumnSection2A texts={ data.benchmarks_texts } />}
              </div>
            </div>
          </div>

          <div className="comparisons_plates-grid-item">
            <div className="comparisons_plate -full-height">
              <h3 className="comparisons_plate-title -md -bordered">Verbatim Themes AI Methodology</h3>
              <div className="comparisons_plate-text">
                <p>
                  Full themes and verbatim quotes are found in the first Appendix section.
                </p>
                <p className="space-md">
                  These summaries were created with our BuzzIQ AI technology.
                  { ' ' }
                  It automatically summarizes and surfaces primary themes to help bring the voice
                  { ' ' }
                  of the respondent to life and aid reporting diagnostics. Keep in mind this represents
                  { ' ' }
                  qualitative analysis. Consider reviewing actual responses to increase confidence and/or
                  { ' ' }
                  make appropriate revisions to these summaries.
                </p>
                <p className="space-md">
                  ChatGPT AI functionality is running in a dedicated, isolated, and protected
                  { ' ' }
                  Azure OpenAI environment. No data will be stored, shared, or used to train AI
                  { ' ' }
                  models. To detect and mitigate abuse, Azure OpenAI stores all prompts and
                  { ' ' }
                  generated content securely for up to thirty (30) days.
                </p>
              </div>
            </div>
          </div>

          <div className="comparisons_plates-grid-item">
            <div className="comparisons_plate -full-height">
              <h3 className="comparisons_plate-title -md -bordered">Unhappy Path Risk Level Calculation</h3>
              <div className="comparisons_plate-text">
                <p>When an Unhappy Path is tested, we calculate an Unhappy Path Customer Case Score to understand the impact on Values Me and Ease scores.</p>
                {!data.arrows_mode && <ThirdColumn />}
                {data.arrows_mode && data.benchmarks_texts &&
                  <ThirdColumnA texts={ data.benchmarks_texts } />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const FirstColumnSection1 = ({ n, recommendationTexts }) => {
  return (
    <div className="comparisons_plate-text">
      <ul>
        <li><b className="green">{ recommendationTexts.pass }</b> = +1 SD &gt; Avg</li>
        <li><b className="orange">{ recommendationTexts.discuss }</b> = within 1 SD of Avg</li>
        <li><b className="red">{ recommendationTexts.revise }</b> = -1 SD &lt; Avg</li>
      </ul>
      <p>The current average score for the go-forward Recommendation is { n }.</p>
    </div>
  );
};

const FirstColumnSection1A = ({ texts }) => {
  return (
    <div className="comparisons_plate-text">
      <p>When a filter is applied, the Go-Forward Recommendation is shown as ”N/A”.</p>
      <p>
        The Go-Forward Recommendation compares the Customer Case Score to all other cases tested
        among Total {texts.full_description}. When a filter is applied, the results shown are compared to
        the {texts.total_description} for this specific case.
      </p>
    </div>
  );
};

const FirstColumnSection2 = ({ n }) => {
  return (
    <div className="comparisons_plate-text">
      <ul>
        <li>Positive metrics above one standard deviation of average are shown in <span className="green">green</span>.</li>
        <li>Positive metrics below one standard deviation are shown in  <span className="red">red</span> as are negative metrics that are higher than Avg + 1 SD.</li>
        <li>Negative metrics below one standard deviation of average are not color-coded nor are neutral metrics like “no impact” and “somewhat of an effort.”</li>
      </ul>
      <p>Benchmarks for most metrics are based on averages across { n } tests.</p>
    </div>
  );
};

const FirstColumnSection2A = ({ texts }) => {
  return (
    <div className="comparisons_plate-text">
      <p>Results for filtered data are compared to the {texts.total_description}.</p>
      <ul>
        <li>Positive metrics with an index score above 120 are shown with an up arrow.</li>
        <li>Positive metrics with an index score below 80 are shown with a down arrow as are negative metrics with an index score above 120.</li>
        <li>Negative metrics with an index score below 80 are not indicated with an arrow,
          nor are neutral metrics like “no impact” and “somewhat of an effort.”</li>
      </ul>
    </div>
  );
};

const ThirdColumn = () => {
  return (
    <>
      <p className="space-md">The impact is communicated as a level of risk:</p>
      <ul>
        <li><b className="red">High</b> if the Unhappy Path Customer Case Score lowers the go-forward Recommendation rating.</li>
        <li><b className="orange">Medium</b> when the Unhappy Path Customer Case Score reduces the original Customer Case Score by 10% or more but does not lower the go-forward Recommendation rating.</li>
        <li><b className="green">Low</b> when the Unhappy Path Customer Case Score is within 10% of the original Customer Case Score (i.e., it has little to no impact).</li>
      </ul>
    </>
  );
};


const ThirdColumnA = ({ texts }) => {
  return (
    <p className="space-md">
      The impact is communicated as a level of risk which accounts for the impact of the Unhappy Path on the
      Go-Forward Recommendation, which compares the Customer Case Score to all other cases tested among
      Total {texts.full_description}. As a result, when a filter is applied, the Risk Level
      is shown as “N/A”.
    </p>
  );
};

export default Benchmarks;
