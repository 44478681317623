import React, { Fragment } from 'react';
import { map, range, max, startsWith, findIndex } from 'lodash';
import cn from "classnames";

const Plot = ({ groupBy, intervals, metric, loading }) => {
  const count = Object.keys(intervals).length;

  if (count === 0) {
    return null;
  }

  const keys = Object.keys(intervals);
  const maxVal = max(Object.values(metric.records)) || 0.0;

  const x1 = nx(0, count), x2 = nx(count - 1, count);

  const values = map(range(0, count - 1), (n) => (metric.records[keys[n]] || 0));
  const peaks = minimalFindPeaks(values, count / 25, maxVal / 10.0);

  return (
    <svg className={ cn("scores", { '-loading': loading }) } viewBox={ `0 0 1000 500` }>
      <text
        className="gray"
        fontFamily="Arial"
        fontSize="16px"
        transform="translate(50,250) rotate(-90)"
        textAnchor="middle"
      >
        {metric.title}
      </text>

      <line
        className="gray"
        x1={ x1 } y1="450"
        x2={ x2 } y2="450"
        strokeWidth="3"
      />

      {map(range(1, count), (n) => {
        const value = metric.records[keys[n]] || 0;
        const prevValue = metric.records[ keys[n - 1] ] || 0;

        const x = nx(n, count), prevX = nx(n - 1, count);

        const dy = maxVal === 0 ? 0 : 400.0 * value / maxVal;
        const prevDY = maxVal === 0 ? 0 : 400.0 * prevValue / maxVal;

        return (
          <line
            key={ n }
            className="red"
            x1={ prevX } y1={ 450 - prevDY }
            x2={ x } y2={ 450 - dy }
            strokeWidth="5"
          />
        );
      })}

      {map(range(count), (n) => {
        const x = nx(n, count);
        const value = metric.records[keys[n]] || 0;
        const dy = (maxVal === 0 ? 0 : (400.0 * value / maxVal));

        const showMark = getShowLine(groupBy, n, count, intervals[keys[n]]);
        const showMax = findIndex(peaks, (el) => el === n) > -1 || value === maxVal && maxVal > 0;

        const width = getLabelWidth(groupBy);

        return (
          <Fragment key={ n }>
            {showMark &&
              <>
                <line className="gray" x1={ x } y1="50" x2={ x } y2="450" opacity="0.4" strokeWidth="1" />
                <circle className="gray" cx={ x } cy={ 450 } r={ 5 } />
                <rect className="period-bg" x={ x - width / 2 } y="465" width={ width } height="22" />
                <text fontFamily="Arial" fontSize="12px" fontWeight="bold" x={ x } y="480" textAnchor="middle">
                  {intervals[keys[n]]}
                </text>
              </>}

            {(showMark || showMax) &&
              <>
                <circle
                  className="red-ring"
                  cx={ x }
                  cy={ 450 - dy }
                  r={ 8 }
                  strokeWidth="5"
                />
                <text
                  className="bold"
                  fontFamily="Arial"
                  fontSize="20px"
                  x={ x + (value < 10 ? -7 : -15) }
                  y={ 450 - dy + (value < 12 ? -14 : 34) }
                >
                  {formatV(value, metric.is_percent)}
                </text>
              </>}
          </Fragment>
        );
      })}
    </svg>
  );
};

const formatV = (v, isPercent) => {
  if (isPercent) {
    return `${v}%`;
  }

  return v;
};

const nx = (index, count) => {
  const delta = 400.0 / count;

  return 500 - ((count - 1) * delta) + 2 * delta * index;
};

const getShowLine = (groupBy, n, count, title) => {
  if (groupBy === 'day' && count > 1000) {
    return startsWith(title, "1 Jan");
  } else if (groupBy === 'day' && count > 70) {
    return startsWith(title, "1 ");
  } else if (groupBy === 'day' && count > 24) {
    return n % 3 === 0;
  } else if (groupBy === 'day' && count > 12) {
    return n % 2 === 0;
  } else if (groupBy === 'week' && count > 9) {
    const magic = Math.floor(100 / (800.0 / count));
    return n % magic === 0;
  } else if (groupBy === 'month' && count > 36) {
    return startsWith(title, "Jan");
  }

  return true;
};

const getLabelWidth = (groupBy) => {
  if (groupBy === 'day') {
    return 50;
  }
  if (groupBy === 'week') {
    return 90;
  }

  return 45;
};

const findLocalMaxima = (xs) => {
  const maxima = [];
  // iterate through all points and compare direct neighbors
  for (let i = 1; i < xs.length - 1; ++i) {
    if (xs[i] > xs[i - 1] && xs[i] > xs[i + 1]) {
      maxima.push(i);
    }
  }
  return maxima;
};

const filterByHeight = (indices, xs, height) => {
  return indices.filter((i) => xs[i] > height);
};

const decor = (v, i) => [ v, i ];
const undecor = (pair) => pair[1];
const argsort = (arr) => arr.map(decor).sort().map(undecor);

const filterByDistance = (indices, xs, dist) => {
  const to_remove = Array(indices.length).fill(false);
  const heights = indices.map((i) => xs[i]);
  const sorted_index_positions = argsort(heights).reverse();

  for (const current of sorted_index_positions) {
    if (to_remove[current]) {
      continue;  // peak will already be removed, move on.
    }

    let neighbor = current - 1;  // check on left side of peak
    while (neighbor >= 0 && (indices[current] - indices[neighbor]) < dist) {
      to_remove[neighbor] = true;
      --neighbor;
    }

    neighbor = current + 1;  // check on right side of peak
    while (neighbor < indices.length
    && (indices[neighbor] - indices[current]) < dist) {
      to_remove[neighbor] = true;
      ++neighbor;
    }
  }
  return indices.filter((v, i) => !to_remove[i]);
};


const filterMaxima = (indices, xs, distance, height) => {
  let new_indices = indices;
  if (height !== undefined) {
    new_indices = filterByHeight(indices, xs, height);
  }
  if (distance !== undefined) {
    new_indices = filterByDistance(new_indices, xs, distance);
  }
  return new_indices;
};

const minimalFindPeaks = (xs, distance, height) => {
  const indices = findLocalMaxima(xs);
  return filterMaxima(indices, xs, distance, height);
};

export default Plot;
