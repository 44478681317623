import React from "react";
import pluralize from "pluralize";
import SvgIcon from "../../../../common/components/svg-icon";

const WithDataProject = ({
  countForConcepts, countForProject, checked, handleOnChange, handleUpdateSummary, disabled }
) => {
  return (
    <div className="middle">
      <h3>BuzzIQ Summary</h3>
      <div className="summary">
        Summary has been generated for
        {
          !!countForConcepts &&
          <>
            { ' ' }
            { countForConcepts } { pluralize('item', countForConcepts) }
          </>
        }
        { !!countForConcepts && !!countForProject && <>{' '}and</> }
        {
          !!countForProject &&
          <>
            { ' ' }
            { countForProject } { pluralize('project item', countForProject) }
          </>
        }
        { '.' }
      </div>
      <div className="buttons">
        <a className="link" onClick={ handleUpdateSummary }>
          <SvgIcon name="refresh" />
          Update All Summaries
        </a>
        <div className="checkbox link">
          <input
            type="checkbox"
            id={ 'cb-all-summaries' }
            checked={ checked }
            onChange={ () => { handleOnChange(!checked);} }
            disabled={ disabled }
          />
          <label className="checkbox_label" htmlFor={ 'cb-all-summaries' }>Add to Report</label>
        </div>
      </div>
    </div>
  );
};

export default WithDataProject;
