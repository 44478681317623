import React, { useContext } from 'react';
import SliderRadioButtons from '../../../express/researcher/verizon_customer_case_v2024/components/sliderRadioButtons';
import { IndexData } from './contexts';
import MultiSelect from "./multiSelect";

const Filter = () => {
  const { data, init, timeFrameFrom, timeFrameTo, blank, reloadData, model, setModel } = useContext(IndexData);

  const handleChangeFrom = (ev) => {
    const value = ev.target.value;
    if (value) {
      setModel({ ...model, timeFrameFrom: value, periods: '' });
      reloadData({ newFrom: value, newPeriods: '', model });
    }
  };

  const handleChangeTo = (ev) => {
    const value = ev.target.value;
    if (value) {
      setModel({ ...model, timeFrameTo: value, periods: '' });
      reloadData({ newTo: value, newPeriods: '', model });
    }
  };

  const showReset = model.timeFrameFrom !== timeFrameFrom || model.timeFrameTo !== timeFrameTo;

  const handleChangeBrand = (values) => {
    setModel({ ...model, brandFilter: values });
    reloadData({ newBrandFilter: values, model });
  };

  const handleChangeBlank = (v) => {
    setModel({ ...model, blank: v });
    reloadData({ newBlank: v, model });
  };

  const handleReset = () => {
    setModel({ ...model, timeFrameFrom, timeFrameTo, periods: '' });
    reloadData({
      newFrom: timeFrameFrom,
      newTo: timeFrameTo,
      newPeriods: '',
      model
    });
  };

  return (
    <div className="form_section">
      <div className="form_grid">
        <div className="form_grid-item">
          <h3 className="form_section-title">
            Time Frame
          </h3>
          <div className="form-group -p-relative">
            <input
              className="form-field -md"
              type="date"
              value={ model.timeFrameFrom }
              max={ model.timeFrameTo.replaceAll("-", "/")  }
              onChange={ handleChangeFrom }
            />
            <input
              className="form-field -md -with-margins"
              type="date"
              value={ model.timeFrameTo }
              min={ model.timeFrameFrom }
              onChange={ handleChangeTo }
            />
            {
              showReset &&
              <button
                type="button"
                className="link -reset"
                onClick={ handleReset }
              >
                Reset to default
              </button>
            }
          </div>
        </div>

        <div className="form_grid-item -grow">
          <h3 className="form_section-title">Filter By Brand</h3>
          <div className="form-group">
            <MultiSelect
              selected={ data.brand_filter }
              options={ init.brand_options }
              onChange={ handleChangeBrand }
              placeholder="Select Brand..."
            />
          </div>
        </div>

        <div className="form_grid-item -width-1-4">
          <h3 className="form_section-title">&nbsp;</h3>
          <div className="form-group">
            <SliderRadioButtons
              initValue={ blank }
              options={
                [
                  { value: 'show', title: 'Show Blank Rows' },
                  { value: 'hide', title: 'Hide Blank Rows' }
                ]
              }
              onChange={ handleChangeBlank }
              modificator="-full-width -white-bg -bordered bsg-selector"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
