import React, { useContext } from 'react';
import { map, each } from 'lodash';
import classnames from 'classnames';
import Tooltip from '../../common/tooltip.js';
import renderRawHtml from '../../../../common/render_raw_html.js';
import {
  setQuotasAndSelectionForBreakingQuestion,
  calculateQuotasForQuestion
} from '../../common/logic/calculate_quotas';
import { ListContext } from '../contexts/list_context.js';
import { ItemContext } from '../contexts/item_context.js';
import ToggleManageQuota from '../../common/toggle_manage_quota';
import { ConditionContext } from "../contexts/condition_context";
import BreaksEditQuota from './breaks_edit_quota.js';
import DemoElementContent from "./demo_element_content.js";

const BreaksElementHeader = ({ question }) => {
  const listContextValue = useContext(ListContext);
  const conditionValues = useContext(ConditionContext);
  const { onQuestionChange } = useContext(ItemContext);
  const {
    onDelete, notAvailableLabel, cantStandText, headerClassName,
    quotaDisallowedOptions
  } = listContextValue;

  const platformName = listContextValue.platformName || '';

  const markUnavailable = !question.available && !!notAvailableLabel;
  const cantStandLabel = cantStandText(question);
  const markCantStand = !!cantStandLabel;
  const  tooltip = listContextValue.tooltip(question);

  const onQuotasUpdate = (event, q) => {
    setQuotasAndSelectionForBreakingQuestion(q, q.breaks, conditionValues);
    onQuestionChange(event, q);
  };

  const onManageQuotaUpdate = (event, q) => {
    if (q.manage_quota) {
      each(q.breaks || [], (breakQ) => {
        calculateQuotasForQuestion(breakQ, quotaDisallowedOptions, conditionValues);
      });
      onQuotasUpdate(event, q);
    } else {
      onQuestionChange(event, q);
    }
  };

  const onChangeBreakQuestion = (event) => {
    setQuotasAndSelectionForBreakingQuestion(question, question.breaks, conditionValues);
    onQuestionChange(event, question);
  };

  const qualifiedForNone = question.noneQualify === 'is';
  const toggleQualifiedForNone = (event) => {
    question.noneQualify = qualifiedForNone ? 'is_not' : 'is';
    onQuestionChange(event, question);
  };

  const toggleShowNoneAnswer = (event) => {
    question.none_answer = !question.none_answer;
    if (!question.none_answer) {
      question.noneQualify = 'is_not';
    }
    onQuestionChange(event, question);
  };

  return (
    <div
      className={
        classnames(
          'form_grid-item',
          { '-not-available': markUnavailable || markCantStand },
          `${headerClassName}`
        )
      }
    >
      {
        !!onDelete &&
        <a
          href="#"
          className="delete"
          onClick={ (event) => { onDelete(event, question);} }
        >
          <svg className="icon -i-delete">
            <use xlinkHref="#svg_i-delete" />
          </svg>
        </a>
      }
      {
        markUnavailable &&
        <span className="not-available-question-label" { ...renderRawHtml(notAvailableLabel) } />
      }
      {
        markCantStand &&
        <span className="not-available-question-label" { ...renderRawHtml(cantStandLabel) } />
      }
      <div className="form_plate -breaks">
        <div className="form_plate-title -with-toggle">
          { question.uiTitle() }
          {
            !!tooltip && <Tooltip text={ tooltip } direction={ "right" } forceMargin />
          }
          <ToggleManageQuota
            question={ question }
            onUpdate={ (event, q) => { onManageQuotaUpdate(event, q); } }
          >
            <BreaksEditQuota
              question={ question }
              breaks={ question.breaks }
              showCross
              linkTitle={ "Edit Quotas" }
              title={ "Quotas" }
              onUpdate={ (event, q) => { onQuotasUpdate(event, q); } }
              platformName={ platformName }
            />
          </ToggleManageQuota>
        </div>
        {
          map(question.breaks || [], (subQuestion, index) => (
            <DemoElementContent
              key={ `break${index}` }
              onChange={ (event, breakQ) => {
                onChangeBreakQuestion(event);
              } }
              question={ subQuestion }
            />
          ))
        }
        {
          question.canPickNoneAnswer &&
          <div className="form_metrics-item-top checkbox-solo">
            <div className="checkbox">
              <input
                id={ `id-${question.uiTitle()}-none-answer` }
                type="checkbox"
                checked={ question.none_answer }
                onChange={ () => {} }
              />
              <label
                className="checkbox_label"
                htmlFor={ `id-${question.uiTitle()}-none-answer` }
                onClick={ toggleShowNoneAnswer }
              >
                { question.customPickNoneMessage }
              </label>
            </div>
          </div>
        }
        {
          question.none_answer &&
          <div className="form_metrics-item-top checkbox-solo">
            <div className="checkbox">
              <input
                id={ `id-${question.uiTitle()}-none-answer-qualify` }
                type="checkbox"
                checked={ qualifiedForNone }
                onChange={ () => {} }
              />
              <label
                className="checkbox_label"
                htmlFor={ `id-${question.uiTitle()}-none-answer-qualify` }
                onClick={ toggleQualifiedForNone }
              >
                { question.customNoneMessage }
              </label>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default BreaksElementHeader;
