import React, { useEffect, Suspense, useContext, forwardRef } from 'react';
import { EmailAppContext } from '../contexts';

const EmailTemplate = ({}, ref) => {
  const {
    currentEmail, toInbox, EmailBodyComponent, setEmailBodyComponent
  } = useContext(EmailAppContext);

  useEffect(() => {
    if (currentEmail) {
      import(`../emails/${currentEmail.path}.js`)
        .then((module) => setEmailBodyComponent(() => module.default))
        .catch((error) => console.error("Error loading email component:", error));
    } else {
      setEmailBodyComponent(null);
    }
    return () => {
      setEmailBodyComponent(null);
    };
  }, [ currentEmail ]);

  const handleRefSet = (el) => {
    if (EmailBodyComponent && el) {
      ref.current = el;
    }
  };

  return (
    <div className="email-template" ref={ handleRefSet }>
      {
        currentEmail ? (
          <>
            <div className="email-info">
              <div className="email-info_header">
                <button className="email_back-button -show-mobile" onClick={ toInbox }>
                  <div className="email_back-button-icon" />
                </button>
                <h2 className="email-info_title">{ currentEmail.subject }</h2>
              </div>
              <div className="email-info_grid">
                <div className="email-info_author">
                  <div className="email-info_author-name">
                    { currentEmail.author.name }
                  </div>
                  <div className="email-info_author-address">
                    { currentEmail.author.email }
                  </div>
                </div>
                <div className="email-info_time">
                  { currentEmail.time }
                </div>
              </div>
            </div>

            {
              EmailBodyComponent ? (
                <Suspense>
                  <EmailBodyComponent />
                </Suspense>
              ) : (
                <div>Loading...</div>
              )}
          </>
        ) : (
          <div>Please select an email</div>
        )
      }
    </div>
  );
};

export default forwardRef(EmailTemplate);
