import Http from './http';

const vccPageEvent = (kind, page, case_ids = [], download_kind = null, project_ids = []) => {
  pageEvent(
    {
      kind,
      page,
      case_ids,
      project_ids,
      download_kind,
      dashboard_kind: 'verizon_customer_case'
    }
  );
};

const vccPageEvents = (kinds, page, case_ids = []) => {
  pageEvent(
    {
      kinds,
      page,
      case_ids,
      dashboard_kind: 'verizon_customer_case'
    }
  );
};

const pageEvent = (params) => {
  Http.post('/page_events', params).then(
    () => {},
    (reject) => {
      console.log('pageEvent: something went wrong', reject);
    }
  );
};

export { pageEvent, vccPageEvent, vccPageEvents };
