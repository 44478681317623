import React, { useContext, useEffect, useState } from 'react';
import { keys, pickBy, isEmpty, compact, map, some, every, filter, uniq } from 'lodash';
import { BaseContext } from '../contexts';
import Http from '../../../../../common/http';
import PoweredByBuzzback
  from '../../../../../express/researcher/verizon_customer_case_v2024/common/components/poweredByBuzzback';
import { vccPageEvent } from '../../../../../common/pageEventHelper';
import TabsWithFilter from './tabsWithFilter';
import Header from './header';
import Scorecard from './scorecard';
import Challenges from './challenges';
import Diagnostics from './diagnostics';
import Unhappy from './unhappy';
import CaseComparisonList from './caseComparisonList';

const Comparison = ({ loading, setLoading }) => {
  const [ data, setData ] = useState({});
  const [ tab, setTab ] = useState('scorecard');
  const [ showList, setShowList ] = useState(false);
  const { selectedForComparison, setSelectedForComparison, verizonRoles } = useContext(BaseContext);
  const [ selectedOptions, setSelectedOptions ] = useState([]);
  const [ selectedBsg, setSelectedBsg ] = useState('none');

  const pids = keys(selectedForComparison);
  const pidsTotal = pids.length;
  const pidsSelected = keys(pickBy(selectedForComparison, (hash) => (hash.show)));
  const pidsSelectedTotal = compact(pidsSelected).length;

  const getData = (filters = [], selectedBsg = 'none') => {
    setLoading(true);
    Http.get(
      `/verizon_customer_case_v2024/api/comparisons`,
      { pids: pids.join(','), sub_groups: filters.join(','), selected_bsg: selectedBsg }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          setData(http.data);
          setSelectedOptions(http.data.selected_sub_groups);
        }
        setLoading(false);
      },
      (reject) => {console.log('something went wrong', reject);});
  };

  const onFilterApply = (filters, selected) => {
    const filterRow = map(filters, (item) => (item.value));
    getData(filterRow, selectedBsg);

    if (selected.option) {
      const caseIds = map(pidsSelected, (pid) => (data.scorecard_data[pid].case_id));
      if (verizonRoles.length) {
        vccPageEvent(`filter_${selected.option.value}`, 'comparison', caseIds);
      }
    }
  };

  const onBsgApply = (newSelectedBsg) => {
    setSelectedOptions([]);
    setSelectedBsg(newSelectedBsg);
    getData([], newSelectedBsg);
  };

  useEffect(() => {
    if (pidsTotal) {
      getData();
    }
  }, []);

  const hasBsg = !!data.scorecard_data && some(pidsSelected, (pid) => {
    return data.scorecard_data[pid].subgroup !== 'none';
  });

  const bts = brandedTexts(data, pidsSelected);

  return (
    <div className="content_body vcc-scorecard">
      {loading && <div className="page-loader -inner-loader -transparent -fixed" />}

      {!pidsTotal && <h3>No cases selected</h3>}

      {!!pidsTotal && !isEmpty(data) &&
        <>
          <Header
            selected={ pidsSelectedTotal }
            total={ pidsTotal }
            setShowList={ setShowList }
          />

          <TabsWithFilter
            tab={ tab }
            setTab={ setTab }
            pids={ pidsSelected }
            subGroups={ data.sub_groups }
            selectedOptions={ selectedOptions }
            setSelectedOptions={ setSelectedOptions }
            onFilterApply={ onFilterApply }
            onBsgApply={ onBsgApply }
            hasBsg={ hasBsg }
            selectedBsg={ selectedBsg }
          />

          {tab === 'scorecard' && <Scorecard pids={ pidsSelected } data={ data } vccCustomerFirst={ bts.vccCustomerFirst } />}
          {tab === 'challenges' && <Challenges pids={ pidsSelected } data={ data }  />}
          {tab === 'diagnostics' && <Diagnostics pids={ pidsSelected } data={ data } brandedTexts={ bts } />}
          {tab === 'unhappy' && <Unhappy pids={ pidsSelected } data={ data } vccCustomerFirst={ bts.vccCustomerFirst } />}

          <PoweredByBuzzback />

          {showList &&
            <CaseComparisonList
              selectedForComparison={ selectedForComparison }
              setSelectedForComparison={ setSelectedForComparison }
              data={ data }
              selected={ pidsSelectedTotal }
              total={ pidsTotal }
              setShowList={ setShowList }
            />}
        </>
      }
    </div>
  );
};

const brandedTexts = (data, pids) => {
  if (!data.scorecard_data || !pids.length) {
    return {};
  }

  const allUnbranded = every(pids, (pid) => (data.scorecard_data[pid].branded === 'unbranded'));
  const allBranded = every(pids, (pid) => (data.scorecard_data[pid].branded === 'branded'));
  const allSame = every(pids, (pid) => (data.scorecard_data[pid].branded === data.scorecard_data[pids[0]].branded));
  const company = (allUnbranded || !allSame) ? 'Company' : data.scorecard_data[pids[0]].branded_report_text;
  const bpids = filter(pids, (pid) => (data.scorecard_data[pid].branded !== 'unbranded'));
  const companiesTexts = uniq(map(bpids, (pid) => (data.scorecard_data[pid].branded_report_text)));
  const companies = allUnbranded ? 'company' : companiesTexts.join(' / ');
  return {
    vccCustomerFirst: data.branded_texts.vcc_customer_first_raw.replace('%{company}', company),
    solution: allBranded ? data.branded_texts.solution_branded : data.branded_texts.solution_mixed,
    vccOpinionCode3: data.branded_texts.vcc_opinion_code3_raw.replace('%{companies}', companies),
    vccOpinionCode1: data.branded_texts.vcc_opinion_code1_raw.replace('%{companies}', companies)
  };
};

export default Comparison;
