import React, { useContext } from 'react';
import classnames from 'classnames';
import Tooltip from '../../common/tooltip.js';
import renderRawHtml from '../../../../common/render_raw_html.js';
import { calculateQuotasForQuestion } from '../../common/logic/calculate_quotas';
import { ListContext } from '../contexts/list_context.js';
import { ItemContext } from '../contexts/item_context.js';
import ToggleManageQuota from '../../common/toggle_manage_quota';
import { ConditionContext } from "../contexts/condition_context";
import DemoElementContent from './demo_element_content.js';
import MappingEditQuota from './mapping_edit_quota';
import EditDemoQuota from './edit_demo_quota';

const DemoElementHeader = ({ readOnly, showText, question, elementClass }) => {
  const listContextValue = useContext(ListContext);
  const conditionValues = useContext(ConditionContext);
  const { onQuestionChange } = useContext(ItemContext);
  const {
    onDelete, notAvailableLabel, cantStandText, quotaDisallowedOptions,
    headerClassName, showDisplayDedicatedPage
  } = listContextValue;
  const markUnavailable = !question.available && !!notAvailableLabel;
  const cantStandLabel = cantStandText(question);
  const markCantStand = !!cantStandLabel;
  const tooltip = listContextValue.tooltip(question);

  const quotaTitle = () => {
    if (question.is_age) {
      return question.manage_quota ? 'Quotas and Age Breaks' : 'Age Breaks';
    }
    return 'Quotas';
  };

  const quotaLinkTitle = () => {
    return `Edit ${quotaTitle(question)}`;
  };

  const onManageQuotaUpdate = (event, q) => {
    if (q.manage_quota) {
      calculateQuotasForQuestion(q, quotaDisallowedOptions, conditionValues);
    }
    onQuestionChange(event, q);
  };

  const qualifiedForNone = question.noneQualify === conditionValues.selected;
  const toggleQualifiedForNone = (event) => {
    question.noneQualify = qualifiedForNone ? 'is_not' : conditionValues.selected;
    onQuestionChange(event, question);
  };

  const toggleShowNoneAnswer = (event) => {
    question.none_answer = !question.none_answer;
    if (!question.none_answer) {
      question.noneQualify = 'is_not';
    }
    onQuestionChange(event, question);
  };

  const toggleDisplayDedicatedPage = (event) => {
    question.display_dedicated_page = !question.display_dedicated_page;
    onQuestionChange(event, question);
  };

  return (
    <div
      className={
        classnames(
          'form_grid-item',
          elementClass,
          { '-not-available': markUnavailable || markCantStand, [headerClassName]: headerClassName },
          `${headerClassName ? headerClassName : ''}`
        )
      }
    >
      {
        !!onDelete &&
        <a
          href="#"
          className="delete"
          onClick={ (event) => {
            onDelete(event, question);
          } }
        >
          <svg className="icon -i-delete">
            <use xlinkHref="#svg_i-delete" />
          </svg>
        </a>
      }
      {
        markUnavailable &&
        <span className="not-available-question-label" { ...renderRawHtml(notAvailableLabel) } />
      }
      {
        markCantStand &&
        <span className="not-available-question-label" { ...renderRawHtml(cantStandLabel) } />
      }
      <div className={ classnames("form_plate", { '-country': question.is_country }) }>
        <div className="form_plate-title -with-toggle">
          { question.uiTitle() }
          {
            !!tooltip &&
            <Tooltip text={ tooltip } direction={ 'right' } forceMargin />
          }
          <ToggleManageQuota
            question={ question }
            onUpdate={ onManageQuotaUpdate }
            leaveLink={ question.is_age }
          >
            {
              !question.quotaMappingList &&
              <EditDemoQuota
                question={ question }
                showCross
                linkTitle={ quotaLinkTitle() }
                title={ quotaTitle() }
                onUpdate={ onQuestionChange }
                editable={ question.is_age }
              />
            }
            {
              !!question.quotaMappingList &&
              <MappingEditQuota
                question={ question }
                mappingTitle={ question.quotaMappingTitle }
                showCross
                linkTitle={ quotaLinkTitle() }
                title={ quotaTitle() }
                onUpdate={ onQuestionChange }
              />
            }
          </ToggleManageQuota>
        </div>
        {showText &&
          <div className="form_plate-title -secondary">
            {question.text}
          </div>}
        <DemoElementContent
          readOnly={ readOnly }
          question={ question }
          onChange={ onQuestionChange }
        />
        {
          question.canPickNoneAnswer &&
          <div className="form_metrics-item-top checkbox-solo">
            <div className="checkbox">
              <input
                id={ `id-${question.uiTitle()}-none-answer` }
                type="checkbox"
                checked={ question.none_answer }
                onChange={ () => {} }
              />
              <label
                className="checkbox_label"
                htmlFor={ `id-${question.uiTitle()}-none-answer` }
                onClick={ toggleShowNoneAnswer }
              >
                { question.customPickNoneMessage }
              </label>
            </div>
          </div>
        }
        {
          question.none_answer && question.customNoneMessage &&
          <div className="form_metrics-item-top checkbox-solo">
            <div className="checkbox">
              <input
                id={ `id-${question.uiTitle()}-none-answer-qualify` }
                type="checkbox"
                checked={ qualifiedForNone }
                onChange={ () => {} }
              />
              <label
                className="checkbox_label"
                htmlFor={ `id-${question.uiTitle()}-none-answer-qualify` }
                onClick={ toggleQualifiedForNone }
              >
                { question.customNoneMessage }
              </label>
            </div>
          </div>
        }
        {showDisplayDedicatedPage &&
          <>
            <hr />
            <div className="form_metrics-item-top checkbox-solo">
              <div className="checkbox">
                <input
                  id={ `id-${question.name}-display-dedicated-page` }
                  type="checkbox"
                  checked={ question.display_dedicated_page }
                  onChange={ toggleDisplayDedicatedPage }
                />
                <label
                  className="checkbox_label"
                  htmlFor={ `id-${question.name}-display-dedicated-page` }
                >
                  Display on dedicated page
                </label>
              </div>
            </div>
          </>}
      </div>
    </div>
  );
};

export default DemoElementHeader;
