import React from 'react';
import EmailBauApp from "./bau/index";

// conceptName = 'BAU'
const App = ({ conceptName }) => {
  switch (conceptName) {
    case 'BAU':
      return <EmailBauApp />;
    default:
      return null;
  }
};

export default App;
