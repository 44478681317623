import React from 'react';

const Example1 = () => {
  return (
    <div className="email">
      <div className="email_header">
        <div className="email_header-logo">
          <svg className="email_header-logo-img">
            <use xlinkHref={ `#svg_verizon-long-branded` } />
          </svg>
        </div>
        <ul className="email_header-menu">
          <li>Shop</li>
          <li>Support</li>
          <li>My Verizon</li>
        </ul>
      </div>
      <div className="email_hero">
        <div className="email_hero-content">
          <h1 className="email_hero-title">
            Big thanks
            <br />
            to you
          </h1>
          <div className="email_hero-text">
            Here are two discounts to lower your monthly bill
          </div>
        </div>
        <div className="email_hero-image">
          <svg className="email_hero-image-img">
            <use xlinkHref={ `#svg_verizon-short-branded` } />
          </svg>
        </div>
      </div>
      <div className="email_content">

        <div className="email_content-section">
          <div className="email_content-intro">
            [First_Name], because you have our mobile and home services, we want to thank you with two discounts that will help you lower your monthly bill.
          </div>
        </div>

        <div className="email_content-section">
          <div className="email_content-cols">
            <div className="email_content-col">
              <div className="email_content-plate -black">
                <h4 className="email_content-plate-title">
                  Claim your Mobile+Home Discount
                </h4>
                <p className="email_content-plate-p">
                  You’re already eligible to save $5/line on your Verizon Wireless bill each month.
                </p>
                <p className="email_content-plate-link">
                  It’s easy to enroll
                  <span className="email_content-plate-link-icon" />
                </p>
              </div>
            </div>
            <div className="email_content-col">
              <div className="email_content-plate -black">
                <h4 className="email_content-plate-title">
                  12 months of additional savings
                </h4>
                <p className="email_content-plate-p">
                  As an added bonus, here’s a $10/mo discount on each active phone line for one year.
                </p>
                <p className="email_content-plate-p">
                  Call us at 800.922.0204 to apply it on your bill, or dial *611 from your mobile phone.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="email_content-section">
          <div className="email_content-cols">
            <div className="email_content-col">
              <h3 className="email_content-section-title">
                Here’s how it would look for a one-line account:
              </h3>
            </div>
            <div className="email_content-col">
              <div className="email_content-plate -stone">
                <div className="email_content-plate-section">
                  <h4 className="email_content-plate-section-title">
                    Mobile + Home Discount:
                  </h4>
                  <div className="email_content-plate-section-text">
                    $5/mo per line x 12 = $60
                  </div>
                </div>
                <div className="email_content-plate-section">
                  <h4 className="email_content-plate-section-title">
                    Bonus discount:
                  </h4>
                  <div className="email_content-plate-section-text">
                    $10/mo per line x 12 = $120
                  </div>
                  <div className="email_content-plate-section-hint">
                    For your first year of savings.
                  </div>
                </div>
                <div className="email_content-plate-section">
                  <h4 className="email_content-plate-section-title">
                    Total annual savings: $180
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="email_footer">
        <ul className="email_footer-menu">
          <li>Phones</li>
          <li>Accessories</li>
          <li>Plans</li>
          <li>myAccess</li>
          <li>My Verizon app</li>
          <li>Contact us</li>
        </ul>
        <h4 className="email_footer-title">
          Account number ending in: 7654-321
        </h4>
        <div className="email_footer-texts">
          <p>
            Must be Verizon Mobile & Fios Home Internet customers. Offer excludes prepaid plans. All discounts apply as long as Verizon provides & you maintain both services & both services remain enrolled in Mobile + Home Discount.
          </p>
          <p>
            © 2024 Verizon
          </p>
          <p>
            This email was sent to username@domain.com. We respect your privacy. Please review our <span className="underlined">Privacy Policy</span>. You may <span className="underlined">unsubscribe</span> from Verizon promotional emails at any time.
          </p>
          <p>
            Verizon, One Verizon Way, Mail Code: 180WVB, Basking Ridge, NJ 07920
          </p>
        </div>
      </div>
    </div>
  );
};

export default Example1;
