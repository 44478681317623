import React, { useContext } from 'react';
import { map } from "lodash";
import cn from "classnames";
import { EmailAppContext } from '../contexts';

const Inbox = () => {
  const { inboxList, readItem, onEmailClick } = useContext(EmailAppContext);
  return (
    <div className="inbox">
      <h2 className="inbox_title">Inbox</h2>
      <div className="inbox_list">
        {
          map(inboxList, (item, index) => (
            <div
              key={ index }
              className={
                cn(
                  "inbox_item -hoverable",
                  { '-clickable': item.path },
                )
              }
              onClick={ () => onEmailClick(item) }
            >
              <div className={ cn("inbox_item-grid", { "-read": readItem(item) }) }>
                <div className="inbox_item-col inbox_item-name">
                  { item.author.name }
                </div>
                <div className="inbox_item-col inbox_item-content">
                  <div className="inbox_item-subject">
                    { item.subject }
                  </div>
                  <div className="inbox_item-preview">
                    { item.preview }
                  </div>
                </div>
                <div className="inbox_item-col inbox_item-time">
                  { item.time }
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default Inbox;
