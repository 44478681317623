import React, { useEffect, useRef } from 'react';
import cloud from "d3-cloud";
import { each } from "lodash/fp";
import { reverse, sortBy, map } from "lodash";
import * as d3 from "d3";

const rangeConstants = {
  defaultWidth: 800,
  defaultHeight: 400,
  maxMargin: 7,
  rangeMaxNum: 6,
  rangeMinNum: 30,
  reductionKoefNum: 10
};

const ExpressWordCloud = ({
  onWordClick, words, noFill, fontFamily,
  positiveColor, neutralColor, negativeColor, emptyColor,
  size, useTranslation
}) => {
  const COLORS = {
    green: positiveColor || '#00812e',
    yellow: neutralColor || '#ffbc3c',
    red: negativeColor || '#ed1c24'
  };

  const sortedWords = map(
    reverse(sortBy(words, 'total')),
    (el) => {
      return {
        color: el.color,
        statusName: el.status_name,
        total: el.total,
        text: (useTranslation ? (el.translated_text || el.text) : el.text)
      };
    }
  );

  const ref = useRef(null);

  const width = size.width || rangeConstants.defaultWidth;
  const height = size.height || rangeConstants.defaultHeight;
  const maxMargin = rangeConstants.maxMargin;
  let rangeMax = Math.round(width / rangeConstants.rangeMaxNum);
  const rangeMin = Math.round(width / rangeConstants.rangeMinNum);

  let layout = null;

  const initLayout = () => {
    const range = rangeMax - rangeMin;
    const reducingCoef = Math.floor(range / rangeConstants.reductionKoefNum);

    return cloud()
      .size([ width, height ])
      .padding(8)
      .rotate(0)
      .fontSize((d) => d.size)
      .text((d) => { return d.text; })
      .on('end', (output) => {
        if (words && words.length !== output.length && rangeMax > rangeMin) {
          rangeMax = Math.max(rangeMax - reducingCoef, rangeMin);
          setRangesAndRun();
        } else {
          draw(output);
        }
      });
  };

  const draw = (sortedWords) => {
    let x0 = -1, x1 = -1, y0 = -1, y1 = -1;

    sortedWords.forEach((d) => {
      if (x0 === -1 || (d.x + d.x0) < x0) {
        x0 = d.x + d.x0;
      }

      if (x1 === -1 || (d.x + d.x0 + d.width) > x1) {
        x1 = d.x + d.x0 + d.width;
      }

      if (y0 === -1 || (d.y + d.y0) < y0) {
        y0 = d.y + d.y0;
      }

      if (y1 === -1 || (d.y + d.y0 + (d.height / 2) + d.padding) > y1) {
        y1 = d.y + d.y0 + (d.height / 2) + d.padding;
      }
    });

    const layoutSize0 = layout.size()[0];
    const layoutSize1 = layout.size()[1];

    //scale value to fit words into viewbox
    const kx = (x1 - x0 === 0 ? 1 : layoutSize0 / (x1 - x0)),
      ky = (y1 - y0 === 0 ? 1 : layoutSize1 / (y1 - y0)),
      k = Math.min(kx, ky) * 0.96;

    //words center point
    const cx = -1 * (x0 + x1) / 2;
    const cy = -1 * (y0 + y1) / 2;

    //viewbox center
    const vx = layoutSize0 / 2;
    const vy = layoutSize1 / 2;

    const svg = d3.select(ref.current);

    svg.select('svg').remove();

    const svgNode = svg.append('svg')
      .attr("width", layoutSize0)
      .attr("height", layoutSize1)
      .attr("viewBox", `0 0 ${layoutSize0} ${layoutSize1}`);

    // transformation works from right to left (which is not very obvious)
    const transform = `translate(${vx} ${vy}) scale(${k}) translate(${cx} ${cy})`;

    const g = svgNode.append("g").attr("transform", transform);

    const nodes = g.selectAll("text").data(sortedWords).enter();

    const list = nodes.append("text")
      .style("font-size", (d) => `${d.size}px`)
      .style("font-family", fontFamily)
      .attr("font-family", fontFamily)
      .attr("text-anchor", "middle")
      .attr("transform", (d) => `translate(${[ d.x, d.y ]}) rotate(${d.rotate}) scale(0.95)`)
      .text((d) => d.text);

    if (noFill) {
      list.style("fill", () => emptyColor || "#919aa9");
    } else {
      list.style("fill", (d) => COLORS[d.statusName] || d.color || emptyColor || "#919aa9");
    }
    if (onWordClick) {
      list.on("click", (d) => onWordClick({ text: d.text }));
    }
  };

  const setRangesAndRun = () => {
    const scale = d3.scaleLinear()
      .domain([ d3.min(sortedWords, (d) => d.total) - 0.1, d3.max(sortedWords, (d) => d.total) + 0.1 ])
      .range([ rangeMin, rangeMax ]);

    // Setup sizes with max margin between words.
    const eachWithIndex = each.convert({ 'cap': false });
    eachWithIndex((w, i) => {
      w.size = Math.floor(scale(w.total));
      const prevW = sortedWords[i - 1];
      if (prevW) {
        if (prevW.size - maxMargin > w.size) {
          prevW.size = w.size + maxMargin;
        }
      }
    })(sortedWords);

    layout.words(sortedWords);
    layout.start();
  };


  useEffect(() => {
    if (ref.current) {
      layout = initLayout();
      setRangesAndRun();
    }
  }, [ ref, noFill, words, useTranslation, size.name ]);

  return (
    <div ref={ ref } />
  );
};

export default ExpressWordCloud;
