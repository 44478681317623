import React from 'react';
import { map, find, some } from 'lodash';
import ImageInPopup from "../../../../../common/components/image_in_popup";
import ErrorMessage from '../common/errorMessage';

const CommonTrs = ({ pids, data }) => {
  const cData = data.scorecard_data;

  const lowNSizePid = find(pids, (pid) => (cData[pid].messages.low_n_size));
  const lowNSizeMessage = lowNSizePid ? cData[lowNSizePid].messages.low_n_size : null;

  const hasDifferingSamples = pids.length && some(pids, (pid) => (
    cData[pid].sample_details !== cData[pids[0]].sample_details
  ));

  return (
    <>
      <tr className="tr-head -sticky">
        <th className="th col-name -sticky-left -no-zindex">
          <b>Case</b>
        </th>
        {map(pids, (pid) => (
          <td key={ pid } className="-center -top col-data">
            <div className="comparisons-table_header-image -minimize">
              <ImageInPopup
                customZIndex={ 1500 }
                src={ cData[pid].image_url }
                hoverClassName="verizon-modifier comparison-image-popup"
              >
                <img width="600" src={ cData[pid].image_url } alt={ cData[pid].name } />
              </ImageInPopup>
            </div>
            <div className="comparisons-table_header-links">
              <div className="comparisons-table_header-link">
                <a
                  href={ `/express/projects/${pid}/concept_comparisons` }
                  rel="noreferrer"
                  target="_blank"
                  className="link"
                  title={ cData[pid].name }
                >
                  {cData[pid].name}
                </a>
              </div>
              <div className="comparisons-table_header-link">
                <a
                  href={ `/express/projects/${pid}/concept_comparisons` }
                  rel="noreferrer"
                  target="_blank"
                  className="link -sm"
                  title="Open Scorecard"
                >
                  Open Scorecard
                </a>
              </div>
            </div>
          </td>
        ))}
      </tr>

      <tr>
        <th className="th col-name -sticky-left -no-zindex">
          <b>Category</b>
        </th>
        {map(pids, (pid) => (
          <td key={ pid } className="col-data -center">{cData[pid].category}</td>
        ))}
      </tr>

      <tr>
        <th className="th col-name -sticky-left -no-zindex">
          <div className="bold">Sample Size</div>
          <ErrorMessage message={ lowNSizeMessage?.text } />
        </th>
        {
          map(pids, (pid) => (
            <td key={ pid } className="col-data -center">
              ({ cData[pid].n })
              { !!cData[pid].messages.low_n_size && <span className="red">*</span> }
            </td>
          ))
        }
      </tr>

      <tr>
        <th className="th col-name -sticky-left -no-zindex">
          <div className="bold">Sample Details</div>
          {
            hasDifferingSamples &&
            <div className="red low-base-size">Caution: some cases have differing samples.</div>
          }
        </th>
        {
          map(pids, (pid) => (
            <td key={ pid } className="col-data -center">{cData[pid].sample_details}</td>
          ))
        }
      </tr>

      <>
        <tr>
          <th className="th col-name -sticky-left -no-zindex">
            <b>Vertical</b>
          </th>
          {
            map(pids, (pid) => (
              <td key={ pid } className="col-data -center">{cData[pid].vertical_text}</td>
            ))
          }
        </tr>

        <tr>
          <th className="th col-name -sticky-left -no-zindex">
            <b>Development Stage</b>
          </th>
          {
            map(pids, (pid) => (
              <td key={ pid } className="col-data -center">{cData[pid].development_stage_text}</td>
            ))
          }
        </tr>
      </>
    </>
  );
};

export default CommonTrs;
