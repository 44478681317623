import React, { Fragment } from 'react';
import { map, find } from "lodash";
import cn from "classnames";

const labelsX = 46;
const labelsY = 470;
const labelsW = 170;
const circleX = 250;
const circleY = 200;
const circleR = 200;

const getCoordinatesForPercent = (cx, cy, percent, radius) => {
  const x = Math.cos(2 * Math.PI * (percent - 0.25)) * radius + cx;
  const y = Math.sin(2 * Math.PI * (percent - 0.25)) * radius + cy;
  return [ x, y ];
};

const getPath = (cx, cy, startPercent, percent, radius) => {
  const [ startX, startY ] = getCoordinatesForPercent(cx, cy, startPercent, radius);
  const [ endX, endY ] = getCoordinatesForPercent(cx, cy, startPercent + percent, radius);

  const largeArcFlag = percent > .5 ? 1 : 0;

  return [
    `M ${cx} ${cy}`,
    `L ${startX} ${startY}`,
    `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`
  ].join(' ');
};

const Pie = ({ values }) => {
  const greenValue = find(values, (el) => (el.color === 'green'));
  const orangeValue = find(values, (el) => (el.color === 'orange'));
  const redValue = find(values, (el) => (el.color === 'red'));


  const greenIsSm = greenValue.percent <= 4;
  const orangeIsSm = orangeValue.percent <= 4;
  const redIsSm = redValue.percent <= 4;

  const greenPercent = greenValue.percent === 100 ? 99.999 : greenValue.percent;
  const orangePercent = orangeValue.percent === 100 ? 99.999 : orangeValue.percent;
  const redPercent = redValue.percent === 100 ? 99.999 : redValue.percent;

  const greenR = greenIsSm ?  1.1 * circleR :  0.6 * circleR;
  const greenAP = 0.5 * greenPercent / 100.0
    + (greenPercent < 2 && redPercent < 2 ? 0.01 : 0)
    - (greenPercent < 2 && orangePercent < 2 ? 0.01 : 0);

  const orangeR = orangeIsSm ?  1.1 * circleR :  0.6 * circleR;
  const orangeAP = (greenPercent + 0.5 * orangePercent) / 100.0;

  const redR = redIsSm ?  1.1 * circleR :  0.6 * circleR;
  const redAP = (greenPercent + orangePercent + 0.5 * redPercent) / 100.0
    + (redPercent < 2 && orangePercent < 2 ? 0.01 : 0);

  const [ greenX, greenY ] = getCoordinatesForPercent(circleX, circleY, greenAP, greenR);
  const [ orangeX, orangeY ] = getCoordinatesForPercent(circleX, circleY, orangeAP, orangeR);
  const [ redX, redY ] = getCoordinatesForPercent(circleX, circleY, redAP, redR);

  return (
    <svg className="pie" viewBox={ `0 0 500 500` }>
      <path className="green" d={ getPath(circleX, circleY, 0, greenPercent / 100.0, circleR) } />
      <path className="orange" d={ getPath(circleX, circleY, greenPercent / 100.0, orangePercent / 100.0, circleR) } />
      <path className="red" d={ getPath(circleX, circleY, (greenPercent + orangePercent) / 100.0, redPercent / 100.0, circleR) } />

      {
        greenValue.percent !== 0 &&
        <text
          className={ cn("pie-text", greenIsSm ? 'black' : 'white') }
          fontFamily="Arial"
          fontSize="28px"
          x={ greenX - 11 } y={ greenY + 6 }
        >
          {greenValue.percent}%
        </text>
      }
      {
        orangeValue.percent !== 0 &&
        <text
          className={ cn("pie-text", orangeIsSm ? 'black' : 'white') }
          fontFamily="Arial"
          fontSize="28px"
          x={ orangeX - 11 } y={ orangeY + 6 }
        >
          {orangeValue.percent}%
        </text>
      }
      {
        redValue.percent !== 0 &&
        <text
          className={ cn("pie-text", redIsSm ? 'black' : 'white') }
          fontFamily="Arial"
          fontSize="28px"
          x={ redX - 11 } y={ redY + 6 }
        >
          {redValue.percent}%
        </text>
      }

      {map(values, (v, index) => (
        <Fragment key={ index }>
          <circle className={ v.color } cx={ labelsX + index * labelsW - 24 } cy={ labelsY - 10 } r={ 20 } />
          <text
            fontFamily="Arial"
            fontSize="28px"
            x={ labelsX + index * labelsW }
            y={ labelsY }
          >
            {v.title}
          </text>
        </Fragment>
      ))}

    </svg>
  );
};

export default Pie;
