import React from 'react';

const Block = ({ header, value, hint }) => {
  return (
    <div className="comparisons_plates-grid-item">
      <div className="comparisons_plate -full-height -flex-vertical">
        <h3 className="comparisons_plate-title -no-transform">{header}</h3>
        <hr />
        <div className="comparisons_status -center -black">
          {value}
        </div>
        {!!hint &&
          <div className="comparisons_plate-note -margin-top -center -sm">
            {hint}
          </div>}
      </div>
    </div>

  );
};

export default Block;
