import React, { useContext } from 'react';
import { IndexData } from './contexts';
import Block from './block';

const Visits = () => {
  const { data: { visits } } = useContext(IndexData);

  return (
    <>
      <h2 className="comparisons_section-title">
        Dashboards Visits
      </h2>
      <div className="comparisons_plates">
        <div className="comparisons_plates-grid">
          <Block header="Total Visits" value={ visits.total } hint="Total number of visits across all users." />
          <Block header="Unique Visits" value={ visits.uniq } hint="Number of distinct users who have visited dashboard." />
          <Block header="Visits Per Day" value={ visits.per_day } hint="Average number of visits per day." />
          <Block header="Time Between Visits" value={ visits.time_between } hint="Avg number of days between first and last visit." />
        </div>
      </div>
    </>
  );
};

export default Visits;
