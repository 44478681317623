export const data = {
  inboxList: [
    {
      id: 1,
      subject: "Important information about your new account",
      preview: "Log in to see your recent activity",
      author: {
        email: "fake@mail.com",
        name: "Fidelity Investments"
      },
      time: "10:49 PM"
    },
    {
      id: 2,
      subject: "Start your policy today for $84.75",
      preview: "A great rate from a company you trust",
      author: {
        email: "fake@mail.com",
        name: "Geico"
      },
      time: "5:19 PM"
    },
    {
      id: 3,
      subject: "Save $5 whenever you are hungry",
      preview: "Stop by your local Panera to see what's fresh",
      author: {
        email: "fake@mail.com",
        name: "Panera"
      },
      time: "3:57 PM"
    },
    {
      id: 4,
      subject: "'First Name', you're eligible for monthly discounts on your Verizon account.",
      preview: "Our appreciation for your loyalty.",
      author: {
        email: "verizon@mail.com",
        name: "Verizon"
      },
      time: "2:22 PM",
      target: true,
      path: "example-1"
    },
    {
      id: 5,
      subject: "Weekend plans",
      preview: "Are we still going hiking this weekend?",
      author: {
        email: "fake@mail.com",
        name: "Jessica Smith"
      },
      time: "11:35 AM"
    },
    {
      id: 6,
      subject: "Andrew Smith sent a message to your group conversation",
      preview: "Personal Facebook notification",
      author: {
        email: "fake@mail.com",
        name: "Andrew Smith"
      },
      time: "9:35 AM"
    }
  ]
};
