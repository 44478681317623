import React, { useContext } from 'react';
import pluralize from 'pluralize';
import cn from "classnames";
import { IndexData } from '../contexts';
import Pie from './pie';

const Column2 = () => {
  const { data } = useContext(IndexData);

  const dd = data.snapshot_view;

  return (
    <div className="comparisons_plates-grid-item">
      <div className="comparisons_plate -full-height">
        <div className="comparisons_grid -space-lg">
          <div className="comparisons_grid-item">
            <h3 className="comparisons_plate-title -center fs18">Recommendation</h3>
            <hr />

            <Pie values={ dd.recommendation.values } />

            <div className="comparisons_plate-note -center -sm">
              {dd.recommendation.text}
            </div>

            <div className={ cn("comparisons_status -huge -center", `-${dd.recommendation.avg_color}`) }>
              {dd.recommendation.avg_score}
            </div>

            <h3 className="comparisons_plate-title -center fs18">Average Customer Case Score</h3>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Column2;
