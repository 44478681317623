import React, { useContext } from 'react';
import { IndexData } from './contexts';

const Satisfaction = () => {
  const { data: { satisfaction } } = useContext(IndexData);

  return (
    <>
      <h2 className="comparisons_section-title">
        Overall Survey Satisfaction
      </h2>
      <div className="comparisons_plates">
        <div className="comparisons_plates-grid">
          <div className="comparisons_plates-grid-item">
            <div className="comparisons_plate -full-height -flex-vertical">
              <div className="comparisons_status -center -green">
                {satisfaction['45'].percent.round()}%
              </div>
              <h3 className="comparisons_plate-title -center -no-transform">Very/Somewhat satisfied</h3>
            </div>
          </div>

          <div className="comparisons_plates-grid-item">
            <div className="comparisons_plate -full-height -flex-vertical">
              <div className="comparisons_status -center -black">
                {satisfaction['3'].percent.round()}%
              </div>
              <h3 className="comparisons_plate-title -center -no-transform">Neither satisfied nor dissatisfied</h3>
            </div>
          </div>

          <div className="comparisons_plates-grid-item">
            <div className="comparisons_plate -full-height -flex-vertical">
              <div className="comparisons_status -center -red">
                {satisfaction['12'].percent.round()}%
              </div>
              <h3 className="comparisons_plate-title -center -no-transform">Very/Somewhat dissatisfied</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Satisfaction;
