import React, { useContext } from 'react';
import Block from './block';
import { IndexData } from './contexts';

const PageViews = () => {
  const { data: { pages } } = useContext(IndexData);

  return (
    <>
      <h2 className="comparisons_section-title">
        Page Views
      </h2>
      <div className="comparisons_plates">
        <div className="comparisons_plates-grid">
          <Block header="Total Views" value={ pages.total } hint="Total number of visits across all pages/views." />
          <Block header="Database View" value={ pages.database } />
          <Block header="Single-Case View" value={ pages.scorecard }  />
          <Block header="Comparison" value={ pages.comparison } />
          <Block header="Subgroup comparison" value={ pages.subgroup_comparison } />
        </div>
      </div>
    </>
  );
};

export default PageViews;
