import React, { Fragment, useState } from 'react';
import { map, range } from "lodash";

const AverageCCScore = ({ records }) => {
  const [ hovered, setHovered ] = useState(-1);

  const count = records.length;

  const x1 = nx(0, count), x2 = nx(count - 1, count);

  const xCoord = (x, score, label) => {
    if (label === 'n/a') {
      return x - 21;
    }

    return x + (score >= 10 ? -17 : -9);
  };

  const yCoord = (dy, score, label) => {
    if (label === 'n/a') {
      return 300 - 18;
    }

    return 300 - dy + (score < 12 ? -14 : 34) ;
  };

  return (
    <svg className="scores" viewBox={ `0 0 1000 350` }>
      <text
        className="gray"
        fontFamily="Arial"
        fontSize="18px"
        transform="translate(130,260) rotate(-90)"
      >
        Average CC Score
      </text>

      <line
        className="gray"
        x1={ x1 } y1="450"
        x2={ x2 } y2="450"
        strokeWidth="3"
      />

      {map(range(1, count), (n) => {
        const x = nx(n, count), prevX = nx(n - 1, count);
        const dy = records[n].score * 2.5, prevDY = records[n - 1].score * 2.5;
        return (
          <line
            key={ n }
            className="red"
            x1={ prevX } y1={ 300 - prevDY }
            x2={ x } y2={ 300 - dy }
            strokeWidth="5"
          />
        );
      })}

      {map(range(count), (n) => {
        const x = nx(n, count);
        const score = records[n].score;
        const label = records[n].score_label;
        const dy = records[n].score * 2.5;

        return (
          <Fragment key={ n }>
            <line className="gray" x1={ x } y1="50" x2={ x } y2="300" opacity="0.4" strokeWidth="1" />
            <circle className="gray" cx={ x } cy={ 300 } r={ 5 } />
            <rect className="period-bg" x={ x - 74 } y="315" width="145" height="22" />

            <text fontFamily="Arial" fontSize="12px" fontWeight="bold" x={ x - 67 } y="330">
              {records[n].text}
            </text>

            <circle
              className="red-ring"
              cx={ x }
              cy={ 300 - dy }
              r={ 8 }
              strokeWidth="5"
              onMouseEnter={ () => { setHovered(n); } }
              onMouseLeave={ () => { setHovered(-1); } }
            />

            <text
              className="bold"
              fontFamily="Arial"
              fontSize="28px"
              x={ xCoord(x, score, label) }
              y={ yCoord(dy, score, label) }
            >
              {label}
            </text>

            {n === hovered && <PointTooltip x={ x } dy={ dy } record={ records[n] } />}
          </Fragment>
        );
      })}
    </svg>
  );
};

const PointTooltip = ({ record, x, dy }) => {
  const above50 = record.score > 50 ? 0 : -200;
  const rcm = [ [ 'Proceed', 'proceed' ], [ 'Optimize', 'optimize' ], [ 'Revise', 'revise' ], [ 'N/A', 'na' ] ];
  return (
    <>
      <rect className="period-bg" rx="10" x={ x - 60 } y={ 300 - dy + 40 + above50 } width="120" height="118" />
      <text fontFamily="Arial" fontSize="12px" fontWeight="bold" x={ x - 50 } y={ 300 - dy + 58 + above50 }>
        Cases tested
      </text>
      <text fontFamily="Arial" fontSize="12px" fontWeight="bold" textAnchor="end" x={ x + 48 } y={ 300 - dy + 58 + above50 }>
        {record.n}
      </text>
      <line className="red" x1={ x - 50 } y1={ 300 - dy + 68 + above50 } x2={ x + 50 } y2={ 300 - dy + 68 + above50 } strokeWidth="1" />

      {map(rcm, (vv, index) => (
        <Fragment key={ vv[1] }>
          <text fontFamily="Arial" fontSize="12px" x={ x - 50 } y={ 300 - dy + 88 + above50 + index * 20 }>
            {vv[0]}
          </text>
          <text fontFamily="Arial" fontSize="12px" fontWeight="bold" textAnchor="end" x={ x + 48 } y={ 300 - dy + 88 + above50 + index * 20 }>
            {record[vv[1]]}
          </text>
        </Fragment>
      ))}
    </>
  );
};

const nx = (index, count) => {
  return 500 - ((count - 1) * 100) + 200 * index;
};

export default AverageCCScore;
