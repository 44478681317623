import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import renderRawHtml from '../common/render_raw_html';

const Portal = ({ children }) => {
  const body = document.body || document.querySelector('body');
  const html = document.html || document.querySelector('html');

  const [ element ] = useState(document.createElement('div'));

  useEffect(() => {
    body.appendChild(element);
    body.style.overflow = 'hidden';
    return () => {
      body.removeChild(element);
      body.style.overflow = null;
      //hack for safari to correct behavior of modal in iframe
      const topCoord = html.scrollTop;
      html.scrollTo({ top: 0 });
      html.scrollTo({ top: topCoord });
    };
  }, [ body, element, html ]);


  return createPortal(children, element);
};

const Modal = ({
  title, handleClose,
  classNameHeader, classNameContent, className, classNameDialog,
  children, showFooterButton, onFooterButtonClick, footerButtonName,
  contentRef, modal
}) => {
  const handleBackdropClose = () => {
    if (modal) {
      return;
    }
    handleClose();
  };

  return (
    <Portal>
      <div className={ `modal ${className ? className : ''}` }>
        <div className="modal_backdrop" onClick={ handleBackdropClose } />
        <div className={ classnames("modal_dialog", classNameDialog) }>
          <div className={ classnames("modal_header", classNameHeader) }>
            { !!title && <h6 className="modal_title" { ...renderRawHtml(title) } /> }
            <button className="modal_close" onClick={ handleClose } type="button">
              <svg>
                <use xlinkHref={ `#svg_close` } />
              </svg>
            </button>
          </div>
          <div
            ref={ contentRef }
            className={
              classnames(
                "modal_content",
                classNameContent
              )
            }
          >
            {children}
          </div>
          {
            showFooterButton && (
              <div className="modal_footer">
                <button
                  className="button -full-width"
                  onClick={ onFooterButtonClick ? onFooterButtonClick : handleClose }
                >
                  { footerButtonName ? footerButtonName : 'Close' }
                </button>
              </div>
            )
          }
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
