import React, { useContext } from 'react';
import { includes } from 'lodash';
import { BaseContext } from '../../contexts';
import CreditsAmount from './creditsAmount';
import Travel from './travel';
import DownloadIndicator from './downloadIndicator';
import Profile from './profile';
import Credits from './credits';
import ExpressReport from './expressReport';
import Tags from './tags';
import ConceptsDatabase from './conceptsDatabase';
import VisibilityGroups from './visibilityGroups';
import ProgramHealthView from './programHealthView';
import ExecutiveView from './executiveView';
import Logout from './logout';

const BottomMenu = () => {
  const { menu } = useContext(BaseContext);
  const showCredits = includes(menu, 'showCredits');
  const travel = includes(menu, 'travel');
  const downloads = includes(menu, 'downloads');
  const profile = includes(menu, 'profile');
  const editCredits = includes(menu, 'editCredits');
  const expressReport = includes(menu, 'expressReport');
  const tagManager = includes(menu, 'tagManager');
  const conceptsDatabase = includes(menu, 'conceptsDatabase');
  const visibilityGroups = includes(menu, 'visibilityGroups');
  const programHealthView = includes(menu, 'programHealth');
  const executiveView = includes(menu, 'executiveView');

  return (
    <div className="sidebar_item">
      <nav className="sidebar_nav">
        <ul className="sidebar_nav-list">
          { showCredits && <CreditsAmount /> }
          { travel && <Travel /> }
          { visibilityGroups && <VisibilityGroups /> }
          { executiveView && <ExecutiveView /> }
          { programHealthView && <ProgramHealthView /> }
          { downloads && <DownloadIndicator /> }
          { profile && <Profile /> }
          { editCredits && <Credits /> }
          { expressReport && <ExpressReport /> }
          { tagManager && <Tags /> }
          { conceptsDatabase && <ConceptsDatabase /> }
        </ul>
      </nav>
      <nav className="sidebar_nav">
        <Logout />
      </nav>
    </div>
  );
};

export default BottomMenu;
