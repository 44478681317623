import React, { useContext, useState } from 'react';
import {map, find, replace} from "lodash";
import PoweredByBuzzback
  from '../../../../express/researcher/verizon_customer_case_v2024/common/components/poweredByBuzzback';
import { IndexData } from '../contexts';
import MultiSelect from '../multiSelect';
import AverageCCScore from "./averageCCScore";
import ModalEditor from './modalEditor';

const ChangesOverTime = () => {
  const [ showModal, setShowModal ] = useState(false);

  const { data, init, reloadData, model, setModel } = useContext(IndexData);
  const dd = data.changes_over_time;

  const handleChangeSample = (values) => {
    setModel({ ...model, sample: values });
    reloadData({ newSample: values, model });
  };

  const handleChangeSubgroups = (values) => {
    setModel({ ...model, subgroups: values });
    reloadData({ newSubgroups: values, model });
  };

  const samples = map(data.sample, (el) => {
    const option = find(init.sample_options, (so) => (so.value === el));
    return option.label;
  }).join(', ') || 'all samples';

  return (
    <>
      <div className="comparisons_plates">
        <div className="comparisons_section">
          <h2 className="comparisons_section-title">{ `Change in CC  Over Time - ${replace(data.cases_text, "<>", "&")}` }</h2>
        </div>
        <div className="comparisons_plate-note -sm">
          <i>The following samples are included in the average customer case score calculations: {samples}.</i>
        </div>
      </div>

      <div className="comparisons_plates with-padding">
        <div className="comparisons_section">
          <h3>Average Customer Case Score - Change Over Time</h3>
        </div>
        <hr />

        <div className="form_grid">
          <div className="form_grid-item -width-2-5">
            <h3 className="form_section-title">Sample</h3>
            <div className="form-group">
              <MultiSelect
                selected={ data.sample }
                options={ init.sample_options }
                onChange={ handleChangeSample }
                placeholder="Select Samples..."
              />
            </div>
          </div>
          <div className="form_grid-item -width-2-5">
            <h3 className="form_section-title">Targeted Subgroups</h3>
            <div className="form-group">
              <MultiSelect
                selected={ data.subgroups }
                options={ init.subgroups_options }
                onChange={ handleChangeSubgroups }
                placeholder="Select Subgroups..."
              />
            </div>
          </div>

          <div className="form_grid-item">
            <h3 className="form_section-title">&nbsp;</h3>
            <div className="form-group">
              <button
                className="button"
                onClick={ () => { setShowModal(true); } }
              >
                Edit Time Frames
              </button>
            </div>
          </div>

        </div>

        <div className="comparisons_section -block">
          <AverageCCScore records={ dd } />
        </div>
      </div>

      <PoweredByBuzzback />

      {showModal &&
        <ModalEditor
          hideModal={ () => {setShowModal(false);} }
        />
      }
    </>
  );
};

export default ChangesOverTime;
