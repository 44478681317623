import React, { useState, useEffect } from 'react';
import { each, values, sortBy, cloneDeep, compact, remove, findIndex, find } from 'lodash';
import HeaderLinks from '../common/components/header-links';
import PoweredByBuzzback
  from '../../express/researcher/verizon_customer_case_v2024/common/components/poweredByBuzzback';
import { vccPageEvent } from '../../common/pageEventHelper';
import { GlobalContext } from './contexts';
import BsgAndTabs from './components/bsgAndTabs';
import Scorecard from './components/tabs/scorecard';
import Diagnostics from './components/tabs/diagnostics';
import Unhappy from './components/tabs/unhappy';
import TdColumnAddEdit from './components/tdColumnAddEdit';
import UpdateAddressLine from './components/updateAddressLine';
import { parameterFromUrl } from './helpers';
import { sendRequest } from './requests';

const App = ({
  project_id, case_name, case_id, columns, columns_data, main_column, urls,
  max_data_columns, max_data_columns_slides, filters_list, no_total_column, verizon_roles
}) => {
  const [ loading, setLoading ] = useState(false);
  const [ columnsForData, setColumnsForData ] = useState(columns);
  const [ columnsData, setColumnsData ] = useState(columns_data);
  const [ noTotalColumn ] = useState(no_total_column);

  const sortedColumnsArray = (updatedHash) => (
    sortBy(values(updatedHash), (item) => item.col_index)
  );
  const [ columnsDataList, setColumnsDataList ] = useState(() => sortedColumnsArray(columnsData));
  const [ mainData, setMainData ] = useState(() => (columnsDataList[0]));
  const [ bsg ] = useState(() => (mainData.bsg));
  const [ selectedBsg, setSelectedBsg ] = useState(
    () => (parameterFromUrl('selected_bsg', mainData.selected_bsg))
  );
  const handleUpdateColumnData = (newColumnsForData) => {
    setLoading(true);
    sendRequest(
      urls.index,
      { columns: newColumnsForData, selectedBsg },
      (response) => {
        setColumnsForData(response.data.columns);
        setColumnsData(response.data.columns_data);
        setLoading(false);
      },
      (response) => {
        setLoading(false);
      }
    );
  };
  const updateColumnsData = (updatedHash) => {
    const generateHeaderComponent = ({ index, dataItem, newItem, editable }) => (
      <TdColumnAddEdit
        index={ index }
        dataItem={ dataItem }
        onUpdate={ handleUpdateColumnData }
        newItem={ newItem }
        editable={ editable }
      />
    );
    const columnsDataArray = cloneDeep(sortedColumnsArray(updatedHash));
    if (noTotalColumn) {
      remove(
        columnsDataArray,
        (item) => !findIndex(columnsDataArray, (el) => item === el)
      );
    }
    const defineTitle = (dataItem, index) => {
      if (noTotalColumn) {
        return columnsForData[index]?.name;
      }
      return (index ? columnsForData[index - 1]?.name : dataItem.case_name);
    };
    each(
      columnsDataArray,
      (dataItem, index) => {
        dataItem.headerComponent = generateHeaderComponent(
          {
            index,
            dataItem,
            title: defineTitle(dataItem, index),
            editable: noTotalColumn ? true : !!index
          }
        );
      }
    );
    const addArray = [];
    if (columnsDataArray.length < max_data_columns) {
      addArray.push(
        {
          custom: 'true',
          headerComponent: generateHeaderComponent({ newItem: true })
        }
      );
    }
    return [ ...columnsDataArray, ...addArray ];
  };
  const [ dataAsArray, setDataArray ] = useState(() => (updateColumnsData(columnsData)));
  useEffect(() => {
    const newList = sortedColumnsArray(columnsData);
    setColumnsDataList(newList);
    setMainData(newList[0]);
    setDataArray(updateColumnsData(columnsData));
  }, [ columnsData ]);

  useEffect(() => {
    if (verizon_roles.length) {
      vccPageEvent('page_view_subgroups_comparison', 'subgroup_comparison', [ case_id ]);
    }
  }, []);

  const [ tabsList ] = useState(() => {
    const list = [
      { title: 'Scorecard', value: 'scorecard' },
      { title: 'Diagnostics', value: 'diagnostics' }
    ];
    if (mainData.include_deviation) {
      list.push({ title: 'Unhappy Path Scenario', value: 'unhappy' });
    }
    return list;
  });
  const [ selectedTab, setSelectedTab ] = useState(
    () => (parameterFromUrl('selected_tab', tabsList[0].value))
  );

  const handleChangeBsg = (value) => {
    setLoading(true);
    sendRequest(
      urls.index,
      { columns: columnsForData, selectedBsg: value },
      (response) => {
        setColumnsForData(response.data.columns);
        setColumnsData(response.data.columns_data);
        setLoading(false);
      },
      (response) => {
        setLoading(false);
      }
    );
    setSelectedBsg(value);
  };

  const handleChangeTab = (value) => {
    setSelectedTab(value);
  };

  const globalContextValue = {
    caseId: case_id,
    caseName: case_name,
    mainColumn: main_column,
    maxDataColumns: max_data_columns,
    maxDataColumnsSlides: max_data_columns_slides,
    dataAsArray, mainData,
    columnsForData, setColumnsForData,
    columnsData, setColumnsData,
    selectedTab, setSelectedTab,
    loading, setLoading,
    selectedBsg,
    noTotalColumn,
    filtersList: filters_list,
    verizonRoles: verizon_roles,
    urls
  };

  const reports = [
    {
      title: 'Subgroup Comparison',
      reportTitle: `${case_id} ${case_name} %date%: Subgroup Comparison`,
      name: 'verizon_customer_case_v2024_cc_side_by_side',
      kinds: compact([ 'google_spreadsheet' ]),
      kindTitles: compact([ 'Google Sheets' ]),
      pageEvent: { kind: 'export_subgroup_comparison', page: 'subgroup_comparison', caseIds: [ case_id ] }
    }
  ];
  if ((noTotalColumn ? (columnsDataList.length - 1) : columnsDataList.length) <= max_data_columns_slides) {
    reports[0].kinds.push('google_pdf');
    reports[0].kinds.push('google');
    reports[0].kindTitles.push('PDF');
    reports[0].kindTitles.push('Google Slides');
  }

  let columnsDataForExport = columnsData;
  if (noTotalColumn) {
    columnsDataForExport = {};
    each(
      columnsData,
      (dataItem, dataKey) => {
        const presentInColumns = !!find(columnsForData, (el) => (el.name === dataKey));
        if (presentInColumns) {
          columnsDataForExport[dataKey] = dataItem;
        }
      }
    );
  }

  const dataHash = {
    no_total_column: noTotalColumn,
    columns_for_data: columnsForData,
    columns_data: columnsDataForExport
  };

  return (
    <GlobalContext.Provider value={ globalContextValue }>
      <UpdateAddressLine>
        <div className="content -grey-background verizon-modifier -comparison -research">
          <div className="content_top -sticky -white">
            <header className="header">
              <div className="header_grid">
                <div className="header_grid-item -grow">
                  <h1 className="header_title">
                    <a className="link -verizon-red-color" href={ urls.scorecard } alt={ case_name }>
                      { case_name }
                    </a>
                    { " " }
                    &rarr;
                    { " " }
                    Subgroup Comparison
                  </h1>
                </div>
                <div className="header_grid-item">
                  <HeaderLinks
                    disabled={ noTotalColumn ? (columnsDataList.length - 1 <= 0) : (columnsDataList.length <= 0) }
                    titleClassName="report-title -no-wrap"
                    reports={ reports }
                    dataHash={ dataHash }
                    requestUrl={ `/express/api/projects/${project_id}/export` }
                    requestKind={ "post" }
                  />
                </div>
              </div>
            </header>
          </div>
          <div className="content_body">
            { loading && <div className="page-loader -inner-loader -transparent -fixed" /> }
            <div className="comparisons vcc-scorecard">
              <BsgAndTabs
                tabsList={ tabsList }
                bsg={ bsg }
                selectedBsg={ selectedBsg }
                onChangeBsg={ handleChangeBsg }
                selectedTab={ selectedTab }
                onSelectTab={ handleChangeTab }
              />
              { selectedTab === 'scorecard' && <Scorecard /> }
              { selectedTab === 'diagnostics' && <Diagnostics /> }
              { selectedTab === 'unhappy' && <Unhappy /> }
            </div>
            <PoweredByBuzzback />
          </div>
        </div>
      </UpdateAddressLine>
    </GlobalContext.Provider>
  );
};

export default App;
