import React, { useContext } from 'react';
import classnames from 'classnames';
import { BaseContext } from '../../contexts';

const ProgramHealthView = () => {
  const { initialData, paths, programHealthActive } = useContext(BaseContext);
  const canManageProgramHealthView = initialData.can_manage_program_health_view;
  const programHealthViewUrl = paths.program_health_view;

  return (
    <>
      {
        canManageProgramHealthView &&
        <li className={ classnames("sidebar_nav-list-item", { '-active': programHealthActive }) }>
          <a
            className="sidebar_nav-link -with-icon"
            href={ programHealthViewUrl }
          >
            <svg className="sidebar_nav-link-icon icon -menu-downloads">
              <use xlinkHref="#svg_menu-downloads" />
            </svg>
            <span className="sidebar_nav-link-text">Program Health</span>
          </a>
        </li>
      }
    </>
  );
};

export default ProgramHealthView;
