import React, { useContext, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { map } from "lodash";
import Modal from '../../../../../common/components/modal';
import { IndexData, BaseContext } from '../contexts';
import { vgSelectStyles } from "../helpers";
import Http from '../../../../../common/http';

const VisibilityGroupModal = ({ project, closeModal }) => {
  const { visibilityGroups, setVisibilityGroups, projects, setProjects } = useContext(IndexData);
  const { executiveViewOmitTag } = useContext(BaseContext);
  const [ dirtyValues, setDirtyValues ] = useState(project.visibility_groups);
  const [ loading, setLoading ] = useState(false);

  const handleOnChange = (newValues) => {
    setDirtyValues(map(newValues, (el) => {
      return { value: el.__isNew__ ? '' : el.value, label: el.label };
    }));
  };

  const handleApply = () => {
    setLoading(true);
    Http.post(
      `/verizon_customer_case_v2024/api/projects/${project.id}/visibility_groups`,
      { visibility_groups: dirtyValues }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          project.visibility_groups = http.data.project_visibility_groups;
          setProjects([ ...projects ]);
          setVisibilityGroups(http.data.all_visibility_groups);
          closeModal();
        }
        setLoading(false);
      },
      (reject) => {
        console.log('something went wrong', reject);
        setLoading(false);
      });
  };

  return (
    <Modal
      modalClassName="centered"
      dialogClassName="verizon-modifier -visibility-groups"
      backDropModifier="verizon-modifier -visibility-groups"
      modalContentClass="-overflow-visible"
      modalSize="md"
      modal
    >
      <div className="summary-editor">
        <div className="summary-editor_header">
          <h3 className="summary-editor_title">
            {project.case_name}
          </h3>
        </div>
        <div>
          <h3>Visibility Groups</h3>
          <CreatableSelect
            defaultValue={ project.visibility_groups }
            className="react-select-container"
            classNamePrefix="react-select"
            options={ visibilityGroups }
            onChange={ handleOnChange }
            isSearchable
            isMulti
            autosize={ false }
            maxMenuHeight={ 150 }
            styles={ vgSelectStyles }
            isDisabled={ loading }
          />
        </div>
        {
          executiveViewOmitTag &&
          <p className="tags-note">
            Note: Add
            { ' ' }
            <strong>Omit-Executive-Snapshot</strong>
            { ' ' }
            to exclude case from “Executive Snapshot“ calculations.
          </p>
        }
        <div className="summary-editor_footer">
          <div className="summary-editor_footer-buttons -right">
            <button
              className="button -default"
              type="button"
              onClick={ closeModal }
              disabled={ loading }
            >
              Cancel
            </button>
            <button
              className="button"
              type="button"
              onClick={ handleApply }
              disabled={ loading }
            >
              Apply Changes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VisibilityGroupModal;
