import React, { useContext, useState } from 'react';
import { map, filter } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import classnames from 'classnames';
import Modal from '../../../../../common/components/modal';
import SvgIcon from "../../../../../common/components/svg-icon";
import { IndexData } from "../../index-context";
import ImageInPopup from "../../../../../common/components/image_in_popup";
import InfoToolTip from '../../../../admin/common/infoToolTip';
import useDraggableInPortal from './use-draggable-in-portal';

const CustomizeQuestionsModal = () => {
  const {
    data,
    loadData,
    customizedQuestionData, reportView, setReportView,
    setCustomizedQuestionData, init
  } = useContext(IndexData);

  const { sortingOrderDefault } = init;

  const renderDraggable = useDraggableInPortal();
  const hintTextStart = "Create title allows you to customize the how you refer to this question in the dashboard and reporting";
  const hintTextEnd = "For example, you could edit the question \"How well does this product fit with your brand\" to \"Brand fit\"";

  const initData = () => {
    const baseData = {};
    baseData[customizedQuestionData.id] = reportView?.customMetrics?.[customizedQuestionData.id] || {};
    return baseData;
  };

  const [ questionId ] = useState(customizedQuestionData.id);
  const [ concepts ] = useState(
    () => filter((customizedQuestionData.concepts || []), (el) => el.checked)
  );
  const [ answers, setAnswers ] = useState(customizedQuestionData.answers);
  const [ answersOrderChanged, setAnswersOrderChanged ] = useState(false);
  const [ columns, setColumns ] = useState(customizedQuestionData.columns);
  const [ columnsOrderChanged, setColumnsOrderChanged ] = useState(false);
  const [ customizeData ] = useState(initData());
  const questionSettings = customizeData[customizedQuestionData.id];
  const [ customTitle, setCustomTitle ] = useState(questionSettings?.custom_title);
  const [ autoSort, setAutoSort ] = useState(questionSettings?.concept_auto_sort || 'none');
  const [ comparisonAutoSort, setComparisonAutoSort ] = useState(
    !!questionSettings?.comparison_auto_sort_by
  );
  const [ comparisonAutoSortBy, setComparisonAutoSortBy ] = useState(
    questionSettings?.comparison_auto_sort_by || ''
  );
  const [ comparisonAutoSortDirection, setComparisonAutoSortDirection ] = useState(
    questionSettings?.comparison_auto_sort_direction || 'desc'
  );
  const [ formLoading ] = useState(false);

  const onChangeCustomTitle = (event) => {
    setCustomTitle(event.target.value);
  };

  const onChangeAutoSorting = (event) => {
    setAutoSort(event.target.value);
  };

  const onSaveData = () => {
    const newData = { ...reportView };
    newData.customMetrics = reportView?.customMetrics || {};
    newData.customMetrics[questionId] = reportView?.customMetrics?.[questionId] || {};
    if (customTitle) {
      newData.customMetrics[questionId].custom_title = customTitle;
    }
    if (autoSort) {
      newData.customMetrics[questionId].concept_auto_sort = autoSort;
    }
    newData.customMetrics[questionId].comparison_auto_sort_by = comparisonAutoSortBy || null;
    newData.customMetrics[questionId].comparison_auto_sort_direction = comparisonAutoSortDirection;

    if (answers && answersOrderChanged) {
      const answersArray = map(answers, (answer) => (answer.index));
      correctBrandAsPoint(answersArray);
      newData.customMetrics[questionId].custom_answers_order = map(answersArray, (item) => (parseInt(item)));
    }
    if (columns && columnsOrderChanged) {
      newData.customMetrics[questionId].custom_columns_order = map(columns, (column) => (column.index));
    }
    setReportView({ ...reportView, ...newData });
    loadData(null, null, null, null, { reportView: newData });
    setCustomizedQuestionData(null);
  };

  const correctBrandAsPoint = (answersArray) => {
    if (customizedQuestionData.brand && customizedQuestionData.asPointScale) {
      answersArray.unshift(0);
      answersArray.push(...[ answersArray.length, answersArray.length + 1 ]);
    }
    answersArray.push(...(sortingOrderDefault || []));
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(answers);

    const [ reorderedItem ] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setAnswers(items);
    setAnswersOrderChanged(true);
  };

  const handleOnColumnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(columns);

    const [ reorderedItem ] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setColumns(items);
    setColumnsOrderChanged(true);
  };

  const handleToggleComparisonAutoSort = (value) => {
    if (comparisonAutoSort === value) {
      return;
    }
    if (value) {
      setComparisonAutoSortBy(concepts[0]?.id);
    } else {
      setComparisonAutoSortBy(null);
    }
    setComparisonAutoSort(value);
  };

  const handleSetComparisonAutoSortBy = (event) => {
    setComparisonAutoSortBy(event.target.value);
  };

  const handleSetComparisonAutoSortDirection = (event) => {
    setComparisonAutoSortDirection(event.target.value);
  };

  return (
    <Modal modalSize="sm" modalContentClass="-overflow-visible">
      { formLoading && <div className="page-loader -inner-loader" /> }
      <div className="modal-header -auto-height">
        <span
          className="modal_close icon-link -no-text -gray"
          title="Close"
          role="button"
          onClick={ () => { setCustomizedQuestionData(null); } }
        >
          <SvgIcon className="icon-link_icon" name="i-close" />
          <span className="icon-link_text">Close</span>
        </span>

        <h3 className="modal_title">{ customizedQuestionData.title }</h3>
      </div>
      <div className="modal-description -flex">
        <h3 className="modal-title-sm -non-grow">Custom Question Title</h3>
        <InfoToolTip
          text={
            <>
              { hintTextStart }
              <br />
              <br />
              { hintTextEnd }
            </>
          }
          position="bottom-right"
        />
      </div>

      <div className="modal-input">
        <input
          className="form-field -block"
          type="text"
          value={ customTitle || '' }
          onChange={ (event) => {
            onChangeCustomTitle(event);
          } }
          placeholder="Enter custom title"
        />
      </div>

      {
        data.custom_concept_auto_sort &&
        !customizedQuestionData.grid &&
        customizedQuestionData.availableForAutoSort &&
        <div className="modal-input">
          Auto-Sorting by percentage in scorecard
          <InfoToolTip
            className="-force-margin"
            text={
              <>This will override manual or auto sorting for the scorecard view.</>
            }
            position="bottom-right"
          />
          {' : '}
          <span className="form_inline-select -no-arrow">
            <select
              className="concept-auto-sort"
              value={ autoSort }
              onChange={ onChangeAutoSorting }
            >
              <option value={ 'none' }>None</option>
              <option value={ 'asc' }>Ascending</option>
              <option value={ 'desc' }>Descending</option>
            </select>
          </span>
        </div>
      }
      {
        !!columns.length &&
        <>
          <div className="modal-description -flex">
            <h3 className="modal-title-sm -left -non-grow">Columns</h3>
          </div>
          <div>
            <DragDropContext onDragEnd={ handleOnColumnDragEnd }>
              <Droppable droppableId="columns-area">
                {
                  (provided) => (
                    <ul
                      className="form-question_answers -columns"
                      { ...provided.droppableProps }
                      { ...provided.dragHandleProps }
                      ref={ provided.innerRef }
                    >
                      {
                        map(columns, (column, index) => (
                          <Draggable
                            key={ index }
                            draggableId={ `column-${index}` }
                            index={ index }
                          >
                            {renderDraggable((provided) => (
                              <div
                                className="form-draggable_item"
                                ref={ provided.innerRef }
                                { ...provided.draggableProps }
                                { ...provided.dragHandleProps }
                              >
                                <div className="form-question_grid -custom">
                                  <div
                                    className="form-question_grid-item -first-answer-item"
                                  >
                                    <div
                                      className="form-question_answer-move"
                                      { ...provided.dragHandleProps }
                                    >
                                      <SvgIcon name="i-move" />
                                    </div>
                                  </div>
                                  <div
                                    className="form-question_grid-item -grow"
                                  >
                                    <span className="form-question_answer-item -draggable">{ column.text }</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Draggable>
                        ))
                      }
                      {provided.placeholder}
                    </ul>
                  )
                }
              </Droppable>
            </DragDropContext>
          </div>
        </>
      }
      {
        !!answers.length &&
        <div
          className={
            classnames(
              "modal-description -flex",
              {
                'custom-answers-sorting': (
                  data.custom_concept_auto_sort &&
                  !customizedQuestionData.grid &&
                  customizedQuestionData.availableForAutoSort
                )
              }
            )
          }
        >
          <h3 className="modal-title-sm -left -non-grow">{ customizedQuestionData.grid ? 'Rows' : 'Answers' }</h3>
          {
            data.custom_concept_auto_sort &&
            !customizedQuestionData.grid &&
            customizedQuestionData.availableForAutoSort &&
            <div className="comparisons_tabs">
              <div className="radio-tabs -borderer -gray-bg">
                <label className="radio-tabs_item">
                  <input
                    type="radio"
                    checked={ !comparisonAutoSort }
                    onChange={ (event) => { handleToggleComparisonAutoSort(false, event); } }
                  />
                  <div className="radio-tabs_label">Manual Sorting</div>
                </label>
                <label className="radio-tabs_item">
                  <input
                    type="radio"
                    checked={ comparisonAutoSort }
                    onChange={ (event) => { handleToggleComparisonAutoSort(true, event); } }
                  />
                  <div className="radio-tabs_label">Auto Sorting</div>
                </label>
              </div>
            </div>
          }
        </div>
      }
      {
        comparisonAutoSort &&
        <div className="auto-sort-settings">
          <p>
            By Item:
            { ' ' }
            <span className="form_inline-select -no-arrow">
              <select
                className="concept-auto-sort"
                value={ comparisonAutoSortBy }
                onChange={ handleSetComparisonAutoSortBy }
              >
                {
                  map(concepts, (concept) => (
                    <option key={ `Concept${concept.id}` } value={ concept.id }>
                      { concept.title }
                    </option>
                  ))
                }
              </select>
            </span>
          </p>
          <p>
            Order:
            { ' ' }
            <span className="form_inline-select -no-arrow">
              <select
                className="concept-auto-sort"
                value={ comparisonAutoSortDirection }
                onChange={ handleSetComparisonAutoSortDirection }
              >
                <option value={ 'desc' }>Descending</option>
                <option value={ 'asc' }>Ascending</option>
              </select>
            </span>
          </p>
        </div>
      }
      {
        !!answers.length && !comparisonAutoSort &&
        <>
          <div>
            <DragDropContext onDragEnd={ handleOnDragEnd }>
              <Droppable droppableId="answers-area">
                {
                  (provided) => (
                    <ul
                      className="form-question_answers -answers"
                      { ...provided.droppableProps }
                      { ...provided.dragHandleProps }
                      ref={ provided.innerRef }
                    >
                      {
                        map(answers, (answer, index) => (
                          <Draggable
                            key={ index }
                            draggableId={ `answer-${index}` }
                            index={ index }
                          >
                            {renderDraggable((provided) => (
                              <div
                                className="form-draggable_item"
                                ref={ provided.innerRef }
                                { ...provided.draggableProps }
                                { ...provided.dragHandleProps }
                              >
                                <div className="form-question_grid -custom">
                                  <div
                                    className="form-question_grid-item -first-answer-item"
                                  >
                                    <div
                                      className="form-question_answer-move"
                                      { ...provided.dragHandleProps }
                                    >
                                      <SvgIcon name="i-move" />
                                    </div>
                                  </div>
                                  <div>
                                    {
                                      answer.text &&
                                      <div
                                        className="form-question_grid-item"
                                      >
                                        <span className="form-question_answer-item -draggable">{ answer.text }</span>
                                      </div>
                                    }
                                    {
                                      answer.image &&
                                      <div className="file-uploader">
                                        <div className="form-flavor_image -with-margins">
                                          <ImageInPopup
                                            className="-pointer"
                                            customZIndex={ 1062 }
                                            src={ answer.image }
                                          >
                                            <img className="-pointer" src={ answer.image } alt={ "text" } />
                                          </ImageInPopup>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Draggable>
                        ))
                      }
                      {provided.placeholder}
                    </ul>
                  )
                }
              </Droppable>
            </DragDropContext>
          </div>
        </>
      }

      <div className="modal-footer">
        <button type="button" className="button modal_button" onClick={ onSaveData }>
          Save
        </button>
      </div>
    </Modal>
  );
};

export default CustomizeQuestionsModal;
