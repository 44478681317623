import React, { useContext, useState } from 'react';
import { map } from "lodash";
import cn from "classnames";
import Modal from '../../../../common/components/modal';
import { IndexData } from '../contexts';
import SvgIcon from '../../../../common/components/svg-icon';

const addDays = (dtStr, days) => {
  if (dtStr === "") {
    return dtStr;
  }
  const dt = new Date(dtStr);
  dt.setDate(dt.getDate() + days);
  dt.setTime(dt.getTime() - dt.getTimezoneOffset() * 60000);
  return dt.toISOString().split('T')[0];
};

const ModalEditor = ({ hideModal }) => {
  const { data, reloadData, model, setModel } = useContext(IndexData);

  const getTimeFrames = () => {
    if (data.time_frames.length) {
      return map(data.changes_over_time, (el) => ([ el.from, el.to ]));
    }

    return [ [ data.time_frame.from_formatted, data.time_frame.to_formatted ] ];
  };
  const getErrors = () => {
    if (data.time_frames.length) {
      return map(data.changes_over_time, (_el) => ([ false, false ]));
    }

    return [ [ false, false ] ];
  };
  const [ vv, setVV ] = useState(getTimeFrames);
  const [ errors, setErrors ] = useState(getErrors);
  const [ disabled, setDisabled ] = useState(false);
  const [ wrongPeriods, setWrongPeriods ] = useState(false);

  const handleChange = (newValue, row, col) => {
    setWrongPeriods(false);

    vv[row][col] = newValue;
    setVV([ ...vv ]);
  };

  const saveModalData = () => {
    const newErrors = map(vv, (_el) => ([ false, false ]));
    if (new Date(vv[0][0]) < new Date(data.time_frame.from_formatted) || vv[0][0] === "") {
      setWrongPeriods(true);
      newErrors[0][0] = true;
      setErrors([ ...newErrors ]);
      return;
    }
    if (new Date(vv[vv.length - 1][1]) > new Date(data.time_frame.to_formatted) || vv[vv.length - 1][1] === "") {
      setWrongPeriods(true);
      newErrors[errors.length - 1][1] = true;
      setErrors([ ...newErrors ]);
      return;
    }

    for (let i = 0; i <= vv.length - 2; i++) {
      if (new Date(vv[i][1]) >= new Date(vv[i + 1][0])) {
        setWrongPeriods(true);
        newErrors[i][1] = true;
        newErrors[i + 1][0] = true;
        setErrors([ ...newErrors ]);
        return;
      }
    }

    for (let i = 0; i <= vv.length - 1; i++) {
      if (new Date(vv[i][0]) >= new Date(vv[i][1])) {
        setWrongPeriods(true);
        newErrors[i][0] = true;
        newErrors[i][1] = true;
        setErrors([ ...newErrors ]);
        return;
      }
      if (vv[i][0] === "" || vv[i][1] === "") {
        setWrongPeriods(true);
        if (vv[i][0] === "") {
          newErrors[i][0] = true;
        } else {
          newErrors[i][1] = true;
        }
        setErrors([ ...newErrors ]);
        return;
      }
    }

    const periods = map(vv, (v) => (`${v[0]}|${v[1]}`));
    setDisabled(true);
    setModel({ ...model, periods });
    reloadData({
      newPeriods: periods,
      model,
      callback: () => {
        setDisabled(false);
        hideModal();
      }
    });
  };

  const handleAdd = (index) => {
    if (disabled || vv.length >= 4) {
      return;
    }

    if (index === vv.length - 1) {
      vv.splice(index + 1, 0, [ addDays(vv[index][1], 1), addDays(data.time_frame.to, 0) ]);
    } else {
      vv.splice(index + 1, 0, [ addDays(vv[index][1], 1), addDays(vv[index + 1][0], -1) ]);
    }
    errors.splice(index + 1, 0, [ false, false ]);

    setVV([ ...vv ]);
    setErrors([ ...errors ]);
  };

  const handleDelete = (index) => {
    if (disabled || vv.length <= 1) {
      return;
    }

    vv.splice(index, 1);
    setVV([ ...vv ]);
    errors.splice(index, 1);
    setErrors([ ...errors ]);
  };

  const minDate = (index) => {
    if (index === 0) {
      return data.time_frame.from;
    }

    return addDays(vv[index - 1][1], 1);
  };

  const maxDate = (index) => {
    if (index === vv.length - 1) {
      return data.time_frame.to;
    }

    return addDays(vv[index + 1][0], -1);
  };

  return (
    <Modal
      modalClassName="centered"
      dialogClassName="verizon-modifier -executive-view"
      customZIndex={ 1064 }
    >
      <div className="summary-editor">
        <div className="summary-editor_header">
          <h3 className="summary-editor_title express">
            Edit Time Frames
          </h3>
        </div>
        <div className="summary-editor_body">
          <h3 className="form-header">Time frames between {data.time_frame.from_us} - {data.time_frame.to_us}</h3>

          {map(vv, (el, index) => (
            <div className="form_grid" key={ index }>
              <div className="form_grid-item">
                <h3 className="form_section-title">
                  Time Frame {index + 1}
                </h3>
                <div className="form-group">
                  <input
                    className={ cn("form-field -md", { 'has-error': errors[index][0] }) }
                    type="date"
                    min={ minDate(index) }
                    max={ el[1].replaceAll("-", "/") || "9999/12/31" }
                    value={ el[0] }
                    disabled={ disabled }
                    onChange={ (ev) => {handleChange(ev.target.value, index, 0);} }
                  />
                  <input
                    className={ cn("form-field -md -with-margins", { 'has-error': errors[index][1] }) }
                    type="date"
                    min={ el[0] }
                    max={ maxDate(index).replaceAll("-", "/")  || "9999/12/31" }
                    value={ el[1] }
                    disabled={ disabled }
                    onChange={ (ev) => {handleChange(ev.target.value, index, 1);} }
                  />
                  <div
                    className={ cn("square green", { '-disabled': vv.length >= 4 }) }
                    onClick={ () => {handleAdd(index);} }
                  >
                    <SvgIcon name="plus" />
                  </div>
                  <div
                    className={ cn("square red", { '-disabled': vv.length <= 1 }) }
                    onClick={ () => {handleDelete(index);} }
                  >
                    <SvgIcon name="close" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="summary-editor_footer express">
          <div className="summary-editor_footer-buttons -right">
            {wrongPeriods && <span className="red">Some time frames are invalid</span>}

            <button
              className="button -secondary"
              type="button"
              onClick={ hideModal }
              disabled={ disabled }
            >
              Cancel
            </button>
            <button
              className="button"
              type="button"
              onClick={ saveModalData }
              disabled={ disabled }
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEditor;
