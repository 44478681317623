let currentHash = {
  targetEmailFound: false,
  targetEmailTimeToFind: 0,
  otherEmailClickNumberBeforeFound: 0,
  exitPopupNoClick: false,
  targetEmailHadScroll: false,
  targetEmailScrolledToBottom: false,
  targetEmailMaxScrolledPercentage: 0,
  screenWidth: 0,
  screenHeight: 0
};

const initialData = (init = {}) => {
  currentHash = {
    ...currentHash,
    ...{
      initDate: Date.now(),
      clickedEmails: []
    },
    ...init
  };
};

const statEmailClick = (email) => {
  if (email.target) {
    if (!currentHash.targetEmailFound) {
      currentHash.targetEmailTimeToFind = (Date.now() - currentHash.initDate);
      currentHash.targetEmailFound = true;
      currentHash.otherEmailClickNumberBeforeFound = currentHash.clickedEmails.length;
    }
  }
  currentHash.clickedEmails.push(email);
};

const statCancelExitClick = () => {
  currentHash.exitPopupNoClick = true;
};

const statOpenTargetEmail = (contentEl, templateEl) => {
  const contentElBox = contentEl.getBoundingClientRect();
  const templateElBox = templateEl.getBoundingClientRect();
  if (Math.abs(templateElBox.height - contentElBox.height) > 1) {
    currentHash.targetEmailHadScroll = true;
  }
  currentHash.screenWidth = (
    window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth
  );
  currentHash.screenHeight = (
    window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight
  );
};

const statScrollEmail = (contentEl, templateEl) => {
  const contentElBox = contentEl.getBoundingClientRect();

  const scrolledHeight = contentEl.scrollHeight - contentEl.scrollTop;
  const maxViewHeight = contentElBox.height;
  const maxScrollHeight = contentEl.scrollHeight - contentElBox.height;

  const reachedBottom = (maxScrollHeight > 1) && Math.abs(scrolledHeight - maxViewHeight) <= 1;
  const scrolledPercentage = (
    maxScrollHeight > 0 ? Math.round(contentEl.scrollTop * 100 / maxScrollHeight) : 0
  );

  statOpenTargetEmail(contentEl, templateEl);
  if (!currentHash.targetEmailScrolledToBottom) {
    currentHash.targetEmailScrolledToBottom = reachedBottom;
  }

  const calculatedPercentage = reachedBottom ? 100 : scrolledPercentage;
  if (calculatedPercentage > currentHash.targetEmailMaxScrolledPercentage) {
    currentHash.targetEmailMaxScrolledPercentage = calculatedPercentage;
  }
};

const sendData = (customData = {}) => {
  const postData = { kind: 'completeSurvey', result: { ...currentHash, ...customData } };

  (window.parent || window).postMessage(postData, "*");
};

export {
  initialData, sendData,
  statEmailClick, statCancelExitClick, statOpenTargetEmail,
  statScrollEmail
};
