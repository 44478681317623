import React, { useContext } from 'react';
import { map, replace, sum } from "lodash";
import { IndexData } from './contexts';

const Numbers = () => {
  const { data } = useContext(IndexData);
  const dd = data.numbers;

  return (
    <>
      <div className="comparisons_plates">
        <div className="comparisons_section">
          <h2 className="comparisons_section-title">{ `Number of Cases Tested - ${replace(data.cases_text, "<>", "&")}` }</h2>
        </div>

        <div className="comparisons_plates-grid">
          <RedBorderTable dd={ dd } method="n" redText="Total Number of Cases" total={ data.total } />
        </div>
      </div>

      <div className="comparisons_plates">
        <div className="comparisons_section">
          <h2 className="comparisons_section-title">{ `Average CC Score - ${replace(data.cases_text, "<>", "&")}` }</h2>
        </div>

        <div className="comparisons_plates-grid">
          <RedBorderTable dd={ dd } method="score" redText="All Cases" total={ data.total } />
        </div>
      </div>
    </>
  );
};

const RedBorderTable = ({ dd, method, redText, total }) => {
  const { data } = useContext(IndexData);
  const hideEmpty = (values) => {
    return sum(values) === 0 && data.blank === 'hide';
  };
  return (
    <table className="executive-numbers">
      <thead>
        <tr className="titles">
          <th />
          <th colSpan={ 4 }>Wireless</th>
          <th colSpan={ 5 }>Home Internet</th>
        </tr>
        <tr>
          <th>Total <span className="note">(all cases):</span> <span className="big">{total}</span></th>
          {map(dd.totals, (el, index) => (
            <th key={ index } className={ index === 3 ? 'red-right' : '' }>
              {el.text}
              {!!el.note && <span className="note"> {el.note}</span>}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className="red-line">
          <td>{redText}</td>
          {map(dd.totals, (el, index) => (
            <td key={ index }>{el[method]}</td>
          ))}
        </tr>
        <tr className="sand-line">
          <td>No Targeted Subgroup</td>
          {map(dd.no_subgroup, (el, index) => (
            <td key={ index }  className={ index === 3 ? 'red-right' : '' }>
              {el[method]}
            </td>
          ))}
        </tr>

        {map(dd.records, (record, index) => (
          <tr key={ index }>
            {
              !hideEmpty(map(record.values, (el) => el['n'])) &&
              <>
                <td>{replace(record.text, "<>", "&")}</td>
                {map(record.values, (el, elIndex) => (
                  <td key={ elIndex } className={ elIndex === 3 ? 'red-right' : '' }>
                    {el[method]}
                  </td>
                ))}
              </>
            }
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Numbers;
