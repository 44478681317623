import React, { useContext, useEffect } from 'react';
import { map } from "lodash";
import { LaunchContext } from '../contexts';
import { formatDateTime } from '../../../../common/dateUtils';

const PayedData = () => {
  const {
    loading, setLoading, payedProjects, isLaunched, launchJid, runCheckStatus, relaunchProject
  } = useContext(LaunchContext);

  useEffect(() => {
    if (launchJid) {
      setLoading(true);
      runCheckStatus();
    }
  }, []);

  const handleRelaunch = () => {
    relaunchProject();
  };

  const showTryAgain = !isLaunched && !loading && !launchJid;

  return (
    <div className="content_body launch-content">
      { loading && <div className="page-loader -inner-loader" /> }
      <div className="form form-payed">
        <div className="form_section">
          <h3 className="form_section-title">Payed.</h3>
          <ul className="form_section-list -no-bullet">
            {
              map(payedProjects, (payment, index) => (
                <li key={ index }>
                  {formatDateTime(payment.date)}: N = { payment.nsize }, ${ payment.amount / 100 }
                </li>
              ))
            }
          </ul>
        </div>
      </div>
      {
        showTryAgain &&
        <div className="buttons">
          <div className="title-note">Project has not been launched due to some issue, though it was paid.</div>
          <div className="try-again-button">
            <button className="button" onClick={ handleRelaunch }>
              Try to launch project again
            </button>
          </div>
        </div>
      }
    </div>
  );
};

export default PayedData;
