import React, { Fragment, useContext } from 'react';
import { filter, includes, map, values } from "lodash";
import cn from "classnames";
import Popup from "reactjs-popup";
import StatusCircle from "../../components/status-circle";
import { getMetricKind, IndexData } from "../../index-context";
import {
  hideCustom, showedAverageValue, averageCountWithFlag, n0,
  columnsOrderChanged, columnsOrder
} from '../../helpers';


const TrCustomMetrics = ({ data, checkedConcepts, customMetrics, nFields }) => {
  const { init, sort, averageMode, setCustomizedQuestionData, reportView } = useContext(IndexData);
  const mainData = useContext(IndexData).data;
  const boxes = { "top2": "T2B", "top": "TB" };
  const top3Boxes = { "top2": "T3B", "top": "TB" };
  const [ averageCountNum, hasValue ] = averageCountWithFlag(
    n0(data[nFields?.avg]), averageMode, init.isInternal, data.view
  );

  const setCustomizeQuestionData = (data) => {
    const modalData = {
      id: data.id,
      title: data.text,
      as_point_scale: data.five_point_metric,
      grid: data.sub_kind === 'grid',
      answers: getAnswers(data.name),
      columns: getColumns(data.name, data.id, data.columns_keys),
      availableForAutoSort: true,
      concepts: mainData.concepts
    };
    setCustomizedQuestionData(modalData);
  };

  const renderTitle = (title, data, innerTitle, customize) => (
    <>
      <tr className={ cn({ "-highlighted": !innerTitle }) }>
        <td
          colSpan={ checkedConcepts.length > 3 ? 3 : checkedConcepts.length + 1 }
          className={ cn({ "table-comparison-subtitle": innerTitle }) }
        >
          <div>
            <div>
              { customize ? reportView?.customMetrics?.[data.id]?.custom_title || title : title }
            </div>
            <div>
              {
                customize &&
                <button
                  className="button -secondary -customize button"
                  type="button"
                  onClick={ () => { setCustomizeQuestionData(data); } }
                >
                  Edit title and responses
                </button>
              }
            </div>
          </div>
        </td>
        {
          checkedConcepts.length > 3 &&
          <td
            colSpan={ checkedConcepts.length + 1 - 3 }
            className={ cn({ "table-comparison_name-cell": innerTitle }) }
          />
        }
        <td
          className={
            cn("table-comparison_average-cell", { "-highlighted": !innerTitle })
          }
        />
      </tr>
      {
        !!nFields && !innerTitle &&
        <tr className="custom-metrics-n-row">
          <td className="table-comparison_name-cell">(N)</td>
          {
            map(checkedConcepts, (concept, index) => (
              <td key={ `checkedConcepts${concept.id}${index}` }>
                <span>({ concept[nFields.f] })</span>
              </td>
            ))
          }
          <td className="table-comparison_average-cell">
            { hasValue && '(' }
            { averageCountNum }
            { hasValue && ')' }
          </td>
        </tr>
      }
    </>
  );

  const renderFivePointScaleMetric = (values, title) => (
    map(values.top3_mode ? top3Boxes : boxes, (text, box) => (
      <Fragment key={ box }>
        { gridMetric(values.sub_kind) && box !== "top" && renderTitle(values.option_text, values, true) }
        <tr>
          <td className="table-comparison_name-cell">
            <span className={ cn({ 'sort-link -current': title === sort }) }>
              { text }
            </span>
          </td>
          {
            map(checkedConcepts, (concept, index) => (
              <td key={ `${box}${title}${concept.id}${index}` }>
                <StatusCircle
                  values={ values[concept.id] } box={ box }
                  hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                />
              </td>
            ))
          }
          <td className="table-comparison_average-cell">
            {showedAverageValue(values, averageMode, false, init.isInternal, data.view, box)}
          </td>
        </tr>
      </Fragment>
    ))
  );

  const trigger = (values) => (
    <div
      className={ cn("table-comparison_concept -no-left-padding -concept-card", `js-${values.id}`) }
    >
      <div className="concept-preview table-comparison_concept-preview">
        <div
          className="concept-preview_thumb -dark -less-rounded"
          style={ { 'backgroundImage': `url(${values.image_url})` } }
        />
      </div>
    </div>
  );

  const renderCustomMetric = (values, title) => {
    const rowSortTitle = `${title} - ${values.option_text}`;

    return (
      <tr>
        <td className={ cn("table-comparison_name-cell", { "-grid": values.image_url !== "" }) }>
          {
            values.option_text !== "" &&
            <span className={ cn({ 'sort-link -current': rowSortTitle === sort }) }>
              { values.option_text }
            </span>
          }
          {
            values.image_url !== "" &&
            <Popup
              trigger={ trigger(values) }
              position="top center"
              on={ [ 'hover', 'focus' ] }
              className="th-image-popup"
              mouseEnterDelay={ 1 }
              mouseLeaveDelay={ 1 }
              offsetY={ -200 }
            >
              <div className="-contains-image in">
                <div className="tooltip-inner">
                  <img className="concept-preview_pic-big" src={ values.image_url } />
                </div>
              </div>
            </Popup>
          }
        </td>
        {
          map(checkedConcepts, (concept, index) => (
            <td key={ `T2B${title}${concept.id}${index}` }>
              <StatusCircle
                values={ values[concept.id] } box="top2"
                hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
              />
            </td>
          ))
        }
        <td className="table-comparison_average-cell">
          {showedAverageValue(values, averageMode, false, init.isInternal, data.view)}
        </td>
      </tr>
    );
  };

  const renderCustomGridMetric = (values, title) => {
    return (
      <>
        <>{ renderTitle(values.option_text, values, true) }</>
        <>
          {
            map(values.columns, (column, colIndex) => {
              const rowSortTitle = `${title} - ${values.option_text} || ${values.columns_keys[colIndex]}`;

              return (
                <tr key={ `col-${column}` }>
                  <td className={ cn("table-comparison_name-cell", { "-grid": values.image_url !== "" }) }>
                    <span className={ cn({ 'sort-link -current': rowSortTitle === sort }) }>
                      { column }
                    </span>
                  </td>
                  {
                    map(checkedConcepts, (concept, index) => (
                      <td key={ `T2B${title}${concept.id}${index}` }>
                        <StatusCircle
                          values={ values[concept.id][colIndex + 1] } box="top2"
                          hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                        />
                      </td>
                    ))
                  }
                  <td className="table-comparison_average-cell">
                    {showedAverageValue(values.avg[colIndex + 1], averageMode, false, init.isInternal, data.view)}
                  </td>
                </tr>
              );
            })
          }
        </>
      </>
    );
  };

  const renderChangedCustomGridMetric = (values, title) => {
    return (
      <>
        <>{ renderTitle(values.option_text, values, true) }</>
        <>
          {
            map(columnsOrder(reportView, values.columns_keys, values.id), (colIndex) => {
              const rowSortTitle = `${title} - ${values.option_text} || ${values.columns_keys[colIndex]}`;

              return (
                <tr key={ `col-${values.columns[colIndex - 1]}` }>
                  <td className={ cn("table-comparison_name-cell", { "-grid": values.image_url !== "" }) }>
                    <span className={ cn({ 'sort-link -current': rowSortTitle === sort }) }>
                      { values.columns[colIndex - 1] }
                    </span>
                  </td>
                  {
                    map(checkedConcepts, (concept, index) => (
                      <td key={ `T2B${title}${concept.id}${index}` }>
                        <StatusCircle
                          values={ values[concept.id][colIndex] } box="top2"
                          hideCustom={ hideCustom(values.avg_hide_for_custom, averageMode) }
                        />
                      </td>
                    ))
                  }
                  <td className="table-comparison_average-cell">
                    {showedAverageValue(values.avg[colIndex], averageMode, false, init.isInternal, data.view)}
                  </td>
                </tr>
              );
            })
          }
        </>
      </>
    );
  };

  const fivePointScaleMetric = (values) => (values.five_point_metric === true);
  const filteredCustomMetrics = () => {
    return filter(customMetrics, (metric) => {
      return ((includes(metric.title, 'QFF') || metric.five_point_metric === true) ||
        (metric.five_point_metric !== true && !metric.comparison_skip));
    });
  };
  const customMetricsByName = (name) => {
    return filter(customMetrics, (metric) => {
      return (((!includes(metric.title, 'QFF') && metric.five_point_metric !== true) || metric.sub_kind === 'grid')
        && !metric.comparison_skip && metric.name === name);
    });
  };
  const getAnswers = (name) => {
    const metric = filter(customMetricsByName(name), (item) => (item.answer_key > 0));
    return map(metric, (item) => ({ index: item.answer_key, text: item.option_text, image: item.image_url }));
  };
  const getColumns = (name, id, colKeys) => {
    const columnsArray = values(customMetricsByName(name))[0]?.columns;
    if (columnsOrderChanged(reportView, id)) {
      return map(columnsOrder(reportView, colKeys, id), (index) => ({ index, text: columnsArray[index - 1] }));
    }
    return map(columnsArray, (item, index) => ({ index: index + 1, text: item }));
  };

  const gridMetric = (kind) => (kind === 'grid');

  const metricRenderFunction = (values, title) => {
    if (fivePointScaleMetric(values)) {
      return renderFivePointScaleMetric(values, title);
    } else if (gridMetric(values.sub_kind)) {
      if (columnsOrderChanged(reportView, values.id)) {
        return renderChangedCustomGridMetric(values, title);
      }
      return renderCustomGridMetric(values, title);
    }

    return renderCustomMetric(values, title);
  };

  let itemName = '';
  return (
    <>
      { renderTitle(getMetricKind(data, 'custom_metrics')) }
      {
        map(filteredCustomMetrics(), (values) => {
          const title = values.title;
          const changedName = itemName !== values.name;
          itemName = values.name;
          return (
            <Fragment key={ `${title}-${values.option_text}-${values.option_title}-${values.answer_key}` }>
              {
                changedName &&
                <>{ renderTitle(values.title, values, true, true) }</>
              }
              { metricRenderFunction(values, title) }
            </Fragment>
          );
        })
      }
    </>
  );
};

export default TrCustomMetrics;
