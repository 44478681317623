import React, { Fragment, useContext } from 'react';
import { map, replace } from "lodash";
import { IndexData } from '../contexts';
import { isEmptyV } from '../helpers';

const Column3 = () => {
  const { data } = useContext(IndexData);

  const dd = data.snapshot_view;
  const hideEmpty = data.blank === 'hide';

  return (
    <div className="comparisons_plates-grid-item">
      <div className="comparisons_plate -full-height">
        <div className="comparisons_grid -space-lg">
          <div className="comparisons_grid-item">
            <h3 className="comparisons_plate-title fs18">Journey</h3>
            <hr />
            <Table dd={ dd.journey } hideEmpty={ hideEmpty } />
          </div>
        </div>

        {
          data.brand_filter.length !== 1 &&
          <div className="comparisons_grid -space-lg">
            <div className="comparisons_grid-item">
              <h3 className="comparisons_plate-title fs18">Branded</h3>
              <hr />
              <Table dd={ dd.branded } hideEmpty={ hideEmpty } />
            </div>
          </div>
        }

        <div className="comparisons_grid -space-lg">
          <div className="comparisons_grid-item">
            <h3 className="comparisons_plate-title fs18">Topic</h3>
            <hr />
            <Table dd={ dd.topic } hideEmpty={ hideEmpty } />
          </div>
        </div>
      </div>
    </div>
  );
};

const Table = ({ dd, hideEmpty }) => {
  return (
    <table className="data-table">
      <thead>
        <tr className="-border-bottom">
          <th />
          <th>n</th>
          <th>Average CC Score</th>
        </tr>
      </thead>
      <tbody>
        {map(dd, (d, index) => (
          <Fragment key={ index }>
            { (!hideEmpty || !isEmptyV(d.n) || !isEmptyV(d.avg)) &&
              <tr>
                <td>{replace(d.title, "<>", "&")}</td>
                <td>{d.n}</td>
                <td>{d.avg}</td>
              </tr>
            }
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default Column3;
