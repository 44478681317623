import React, { useState, useRef, useEffect } from 'react';
import {map} from "lodash";

const Example3 = () => {

  return (
    <div className="email">
      <h3>Email 3</h3>
    </div>
  );
};

export default Example3;
