import React, { useState } from 'react';
import { map } from "lodash";
import Http from '../../../common/http';
import Header from './header';
import Filter from './filter';
import SnapshotView from './snapshotView';
import Numbers from './numbers';
import ChangesOverTime from './changesOverTime';
import { IndexData } from "./contexts";

const App = ({ initData, init }) => {
  const [ data, setData ] = useState(initData);
  const [ timeFrameFrom, setTimeFrameFrom ] = useState(data.time_frame.base_start_time);
  const [ timeFrameTo, setTimeFrameTo ] = useState(data.time_frame.base_end_time);
  const [ brandFilter, setBrandFilter ] = useState(data.brand_filter);
  const [ sample, setSample ] = useState(data.sample);
  const [ subgroups, setSubgroups ] = useState(data.subgroups);
  const [ blank, setBlank ] = useState(data.blank);

  const [ model, setModel ] = useState({
    timeFrameFrom: data.time_frame.from_formatted,
    timeFrameTo: data.time_frame.to_formatted,
    brandFilter: data.brand_filter,
    sample: data.sample,
    subgroups: data.subgroups,
    blank: data.blank || 'show',
    periods: data.periods || ''
  });

  const reloadData = ({ newFrom, newTo, newBrandFilter, newBlank, newSample, newSubgroups, newPeriods, model, callback }) => {
    const params = {};

    if (newFrom) {
      params['tf_from'] = newFrom;
    } else {
      params['tf_from'] = model.timeFrameFrom || "";
    }

    if (newTo) {
      params['tf_to'] = newTo;
    } else {
      params['tf_to'] = model.timeFrameTo || "";
    }

    if (newBrandFilter) {
      const vv = map(newBrandFilter, (el) => (el.value));
      setBrandFilter(vv);
      params['brand_filter'] = vv;
    } else {
      const vv = map(model.brandFilter, (el) => (el.value));
      params['brand_filter'] = vv || "";
    }

    if (newSample) {
      const vv = map(newSample, (el) => (el.value));
      setSample(vv);
      params['sample'] = vv;
    } else {
      const vv = map(model.sample, (el) => (el.value));
      params['sample'] = vv || "";
    }

    if (newSubgroups) {
      const vv = map(newSubgroups, (el) => (el.value));
      setSubgroups(vv);
      params['subgroups'] = vv;
    } else {
      const vv = map(model.subgroups, (el) => (el.value));
      params['subgroups'] = vv || [];
    }

    if (newPeriods || newPeriods === "") {
      params['periods'] = newPeriods;
    } else {
      params['periods'] = model.periods;
    }

    if (newBlank) {
      setBlank(newBlank);
      params['blank'] = newBlank;
    } else {
      params['blank'] = model.blank;
    }

    Http.get(
      `/verizon_customer_case_v2024/api/executive`,
      params
    ).then(
      (http) => {
        if (http.response.status === 200) {
          setData(http.data);

          if (callback) {
            callback(http.data);
          }
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const contextValue = {
    data,
    init,
    timeFrameFrom,
    timeFrameTo,
    brandFilter,
    blank,
    model, setModel,
    reloadData
  };

  return (
    <IndexData.Provider value={ contextValue }>
      <div className="content verizon-modifier -research -comparison -executive-view">
        <Header />
        <Filter />
        <SnapshotView />
        <Numbers />
        <ChangesOverTime />
      </div>
    </IndexData.Provider>
  );
};

export default App;
