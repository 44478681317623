import React, { useState, useEffect, useContext } from 'react';
import { createPortal } from 'react-dom';
import { compact } from 'lodash';
import classnames from 'classnames';
import { PopupImageContext } from './image_in_popup_context';

const Portal = ({ children, className, sourceElement, onUpdateCoords }) => {
  const { customZIndex } = useContext(PopupImageContext);
  const body = document.body || document.querySelector('body');
  const [ element ] = useState(document.createElement('div'));
  const initialStyles = 'top: 0; left: 0; position: absolute; visibility: hidden; opacity: 0;';
  const computedHash = () => {
    const margin = 8,
      sourceData = sourceElement.getBoundingClientRect(),
      elementData = element.getBoundingClientRect(),
      bodyData = document.getElementsByTagName('body')[0].getBoundingClientRect();

    let top = sourceData.top - elementData.height - margin,
      left = sourceData.left + sourceData.width / 2 - elementData.width / 2,
      className = 'top',
      subClassName = null;

    if (top < -margin) {
      className = 'right';
      subClassName = 'bottom';
      top = sourceData.top + sourceData.height - elementData.height;
      left = sourceData.left + sourceData.width + margin;
    }

    if (top < -margin) {
      className = 'right';
      subClassName = 'top';
      top = sourceData.top;
      left = sourceData.left + sourceData.width + margin;
    }

    if (
      left + elementData.width - margin > bodyData.width &&
      sourceData.left > bodyData.width - sourceData.left
    ) {
      className = 'left';
      left = sourceData.left - elementData.width - margin;
      if (top + elementData.height <= sourceData.top) {
        subClassName = 'bottom';
      }
    }
    const resultStyles = [
      `top: ${top}px`,
      `left: ${left}px`,
      "position: absolute",
      "visibility: visible",
      "opacity: 1"
    ];
    if (customZIndex) {
      resultStyles.push(`z-index: ${customZIndex}`);
    }

    return { styles: resultStyles.join('; '), className, subClassName };
  };

  useEffect(() => {
    element.className = className;
    element.setAttribute('style', initialStyles);
    body.appendChild(element);
    const settingsHash = computedHash();
    element.className = `${className} ${settingsHash.className}`;
    element.setAttribute('style', settingsHash.styles);
    onUpdateCoords(`${compact([ settingsHash.className, settingsHash.subClassName ]).join('-')}`);
    return () => {
      body.removeChild(element);
    };
  }, []);

  return createPortal(children, element);
};

const HoverPopup = ({ className, sourceElement, children }) => {
  const [ style, setStyle ] = useState({});
  const updateBy = (direction) => {
    const style = {};
    if (direction === 'right-bottom' || direction === 'left-bottom') {
      style.top = '90%';
    } else if (direction === 'right-top' || direction === 'left-top') {
      style.top = '10%';
    }
    setStyle(style);
  };
  return (
    <Portal
      className={ classnames('tooltip -contains-image fade in', className) }
      sourceElement={ sourceElement }
      onUpdateCoords={ (direction) => {
        updateBy(direction);
      } }
    >
      <div className="tooltip-arrow" style={ style } />
      <div className="tooltip-inner">{children}</div>
    </Portal>
  );
};

export default HoverPopup;
