import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { filter, indexOf } from 'lodash';
import { vgSelectStyles } from '../projects/components/helpers';

const MultiSelect = ({ options, selected, onChange, placeholder }) => {
  const values = filter(options, (el) => (indexOf(selected, el.value) > -1));

  return (
    <CreatableSelect
      value={ values }
      className="react-select-container"
      classNamePrefix="react-select"
      options={ options }
      onChange={ onChange }
      isMulti
      autosize={ false }
      maxMenuHeight={ 150 }
      styles={ vgSelectStyles }
      placeholder={ placeholder }
      isValidNewOption={ () => (false) }
    />
  );
};

export default MultiSelect;
