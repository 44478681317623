import React, { useContext, useState, useEffect } from 'react';
import classnames from 'classnames';
import { cloneDeep, remove, find, map } from 'lodash';
import { GlobalContext } from '../contexts';
import Modal from '../../../common/components/modal';
import {
  filtersFromStructureToString, filtersFromStringToStructure, nameRegToReplace, filterNamesFromStructure
} from '../helpers';
import InfoToolTip from '../../../express/admin/common/infoToolTip';
import { vccPageEvents } from '../../../common/pageEventHelper';
import FiltersEditor from './filtersEditor';
import InputAllowed from './inputAllowed';

const TdColumnAddEdit = ({ index, dataItem, newItem, editable, onUpdate }) => {
  const {
    columnsForData, loading, noTotalColumn, maxDataColumns, maxDataColumnsSlides, caseId, verizonRoles
  } = useContext(GlobalContext);
  const [ modalSettings, setModalSettings ] = useState(null);
  const prepareColumnForData = (newList) => {
    if (!editable) {
      return null;
    }
    return noTotalColumn ? newList[index] : newList[index - 1];
  };
  const [ columnForData, setColumnForData ] = useState(
    () => (prepareColumnForData(columnsForData))
  );
  useEffect(() => {
    setColumnForData(prepareColumnForData(columnsForData));
  }, [ columnsForData ]);

  const handleNewColumn = () => {
    setModalSettings({
      new: true,
      current: {
        name: '',
        value: '',
        structure: filtersFromStringToStructure('')
      }
    });
  };
  const handleEditColumn = () => {
    const current = cloneDeep(columnForData);
    current.structure = filtersFromStringToStructure(current.value);
    setModalSettings({ new: false, current });
  };

  const handleCancelEdit = () => {
    setModalSettings(null);
  };

  const handleDelete = () => {
    if (window.confirm("Do you really want to delete this filter?")) {
      remove(columnsForData, (item) => item === columnForData);
      onUpdate([ ...columnsForData ]);
      setModalSettings(null);
    }
  };

  const handleSave = () => {
    const newValue = filtersFromStructureToString(modalSettings.current.structure);
    if (modalSettings.new) {
      modalSettings.current.value = newValue;
      columnsForData.push(modalSettings.current);
    } else {
      columnForData.name = modalSettings.current.name;
      columnForData.value = newValue;
    }
    onUpdate([ ...columnsForData ]);

    const filterNames = filterNamesFromStructure(modalSettings.current.structure);
    if (filterNames.length && verizonRoles.length) {
      vccPageEvents(
        map(filterNames, (f) => (`filter_${f}`)),
        'subgroup_comparison',
        [ caseId ],
      );
    }

    setModalSettings(null);
  };

  const handleOnChangeFilters = () => {
    setModalSettings({ ...modalSettings });
  };

  const updateCurrentName = (newValue) => {
    modalSettings.current.name = newValue;
    setModalSettings({ ...modalSettings });
  };

  const disableSaveButton = (currentItem) => {
    if (loading) {
      return true;
    } else if (!currentItem.name) {
      return true;
    }
    // if same name found
    return !!find(
      columnsForData,
      (item) => (
        item !== columnForData && item.name.toLowerCase() === currentItem.name.toLowerCase()
      )
    );
  };

  return (
    <td className={ classnames("-center col-data", { 'side-by-side-edit': editable }) }>
      {
        newItem ? (
          <InfoToolTip
            remote
            position="top-center"
            showPopup
            attachTo={
              <button className="button new-plus" onClick={ handleNewColumn } />
            }
          >
            <>
              Add up to { maxDataColumnsSlides } custom subgroups to export to Google Slides.
              <br />
              Add up to { maxDataColumns } to export to Google Sheets.
            </>
          </InfoToolTip>
        ) : (
          <div className="comparisons-table_header-links">
            <b>{editable ? columnForData?.name : dataItem.case_name}</b>
            {
              editable &&
              <button className="button edit-icon" onClick={ handleEditColumn }>
                <svg className="edit-pencil">
                  <use xlinkHref={ `#svg_edit-pencil` } />
                </svg>
              </button>
            }
          </div>
        )
      }
      {
        !!modalSettings &&
        <Modal
          modalClassName="centered -side-by-side-modal"
          dialogClassName="verizon-modifier -side-by-side-modal-dialog"
          backDropModifier="verizon-modifier -visibility-groups"
          modalContentClass="-overflow-visible"
          modalSize="md"
          modal
        >
          <div className="summary-editor content-body">
            <div className="summary-editor_header content-body-header">
              <h3 className="summary-editor_title">
                { modalSettings.new ? "Add Filter" : "Edit Filter" }
              </h3>
            </div>

            <div className="content-body-fields">
              <div className="summary-editor_form-group">
                <FiltersEditor
                  structure={ modalSettings.current.structure }
                  onChange={ handleOnChangeFilters }
                />
              </div>
              <h3>Name</h3>
              <div className="summary-editor_form-group">
                <InputAllowed
                  replaceReg={ nameRegToReplace }
                  value={ modalSettings.current.name }
                  onChange={ updateCurrentName }
                />
              </div>
            </div>
            <div className={ classnames("summary-editor_footer", { "-flex": !modalSettings.new }) }>
              {
                !modalSettings.new &&
                <button
                  className="button -vcc-delete"
                  type="button"
                  onClick={ handleDelete }
                  disabled={ loading }
                >
                  Delete
                </button>
              }
              <div className="summary-editor_footer-buttons -right content-body-footer">
                <button
                  className="button -default"
                  type="button"
                  onClick={ handleCancelEdit }
                  disabled={ loading }
                >
                  Cancel
                </button>
                <button
                  className="button"
                  type="button"
                  onClick={ handleSave }
                  disabled={ disableSaveButton(modalSettings.current) }
                >
                  { modalSettings.new ? 'Add' : 'Save'  }
                </button>
              </div>
            </div>
          </div>
        </Modal>
      }
    </td>
  );
};

export default TdColumnAddEdit;
