import React, { useContext } from 'react';
import { replace } from "lodash";
import { IndexData } from "../contexts";
import Column1 from './column1';
import Column3 from './column3';
import Column2 from './column2';

const SnapshotView = () => {
  const { data } = useContext(IndexData);

  return (
    <div className="comparisons_plates">
      <div className="comparisons_section">
        <h2 className="comparisons_section-title">{ `Snapshot View - ${replace(data.cases_text, "<>", "&")}` }</h2>
      </div>

      <div className="comparisons_plates-grid">
        <Column1 />

        <Column2 />

        <Column3 />
      </div>
    </div>
  );
};

export default SnapshotView;
