import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { map, filter } from "lodash";
import cn from "classnames";
import { data } from "../common/data";
import Modal from "../common/modal";
import {
  initialData,
  statEmailClick, statCancelExitClick, statOpenTargetEmail, statScrollEmail,
  sendData
} from '../common/statistics';
import { EmailAppContext } from '../contexts';
import Inbox from "./inbox";
import EmailTemplate from "./emailTemplate";

const EmailBauApp = () => {
  const menuList = [
    {
      name: "inbox",
      title: "Inbox",
      clickable: true,
      icon: "inbox"
    },
    {
      name: "sent",
      title: "Sent",
      icon: "sent"
    },
    {
      name: "drafts",
      title: "Drafts",
      icon: "drafts"
    },
    {
      name: "trash",
      title: "Trash",
      icon: "trash"
    }
  ];

  const { inboxList } = data;

  const [ pathName, setPathName ] = useState("inbox");
  const [ currentEmail, setCurrentEmail ] = useState(null);
  const [ EmailBodyComponent, setEmailBodyComponent ] = useState(null);
  const [ showSidebarMobile, setShowSidebarMobile ] = useState(false);
  const [ showTopStripe, setShowTopStripe ] = useState(false);
  const [ openModal, setOpenModal ] = useState(false);
  const [ finishedExercise, setFinishedExercise ] = useState(false);
  const [ readEmails, setReadEmails ] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setShowTopStripe(true);
    }, 15000);
  }, []);

  const addToReadEmails = (email) => {
    setReadEmails({ ...readEmails, ...{ [email.id]: true } });
  };
  const readItem = (email) => (!!readEmails[email.id]);
  const unreadItems = filter(inboxList, (el) => !readEmails[el.id]);

  const onEmailClick = (email) => {
    statEmailClick(email);
    if (email.path) {
      setCurrentEmail(email);
      addToReadEmails(email);
      setPathName("inboxItem");
    } else {
      console.log("no path");
    }
  };

  const onMenuClick = (item) => {
    if (item.clickable) {
      setPathName(item.name);
      setShowSidebarMobile(false);
    }
  };

  const toggleMenu = () => {
    setShowSidebarMobile(!showSidebarMobile);
  };

  const toInbox = () => {
    setPathName("inbox");
  };

  const handleShowModalFinish = () => {
    setOpenModal(true);
  };

  const closeModalFinish = () => {
    statCancelExitClick();
    setOpenModal(false);
  };

  const finishMailApp = () => {
    sendData();
    setFinishedExercise(true);
  };

  useLayoutEffect(() => {
    initialData();
  }, []);

  const contentRef = useRef(null);
  const templateRef = useRef(null);
  const handleSetContentRef = (el) => {
    if (el) {
      contentRef.current = el;
      contentRef.current.onscroll = () => {
        if (contentRef.current && templateRef.current) {
          statScrollEmail(contentRef.current, templateRef.current);
        }
      };
    }
  };
  useLayoutEffect(() => {
    if (EmailBodyComponent && contentRef.current && templateRef.current) {
      statOpenTargetEmail(contentRef.current, templateRef.current);
    }
  }, [ EmailBodyComponent, contentRef.current, templateRef.current ]);

  const emailAppContextValue = {
    inboxList, readItem, onEmailClick, currentEmail, toInbox,
    EmailBodyComponent, setEmailBodyComponent
  };

  return (
    <EmailAppContext.Provider value={ emailAppContextValue }>
      {
        finishedExercise ? (
          <></>
        ) : (
          <div className="wrapper">
            {
              !!showTopStripe &&
              <div className="top-stripe">
                Please click
                <button className="top-stripe_button" onClick={ handleShowModalFinish }>here</button>
                to return to the survey
              </div>
            }
            <div className="layout">
              <button onClick={ toggleMenu } className="sidebar_toggle">
                <svg className="sidebar_toggle-icon">
                  <use xlinkHref={ `#svg_sandwich` } />
                </svg>
              </button>
              <div
                className={
                  cn(
                    "sidebar",
                    { '-show-mobile': showSidebarMobile },
                  )
                }
              >
                <div className="sidebar_close-mobile" onClick={ toggleMenu } />
                <div className="sidebar_inner">
                  <ul className="menu">
                    {
                      map(menuList, (menuItem, index) => (
                        <li key={ index } className="menu_item">
                          <div
                            className={
                              cn(
                                "menu_link",
                                { '-clickable': menuItem.clickable },
                                { '-active': menuItem.name === pathName ||  `${menuItem.name}Item` === pathName }
                              )
                            }
                            onClick={ () => onMenuClick(menuItem) }
                          >
                            <span className="menu_link-icon">
                              { menuItem.icon &&
                              <svg className={ `menu_link-icon-svg -${menuItem.icon}` }>
                                <use xlinkHref={ `#svg_${menuItem.icon}` } />
                              </svg>
                              }
                            </span>
                            <span className="menu_link-name">{menuItem.title}</span>
                            { menuItem.name === "inbox" && <span className="menu_link-num">{ unreadItems.length }</span> }
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                </div>

              </div>
              <div className="content" ref={ handleSetContentRef }>
                { pathName === 'inbox' && <Inbox /> }
                { pathName === 'inboxItem' && <EmailTemplate ref={ templateRef } /> }
              </div>
            </div>
            {
              openModal &&
              <Modal
                modal
                handleClose={ closeModalFinish }
                title="Do you really want to leave the VZ email and return to the survey?"
                classNameDialog="-sm -finish-app"
              >
                <div className="modal_buttons">
                  <button className="button" onClick={ finishMailApp }>Yes</button>
                  <button className="button -bordered" onClick={ closeModalFinish }>No</button>
                </div>
              </Modal>
            }
          </div>
        )
      }
    </EmailAppContext.Provider>
  );
};

export default EmailBauApp;
