import React from 'react';
import { map } from 'lodash';
import cn from "classnames";
import { runExport } from '../../../express/common/sidebar/requests';
import {
  classByReportKind,
  reportKindsArray,
  reportKindTitle,
  kindByIndex
} from '../../../express/researcher/common/headerLinksHelper';
import { userDownloadCCPlatform } from '../helpers';
import { vccPageEvent } from '../../../common/pageEventHelper';
import DropdownButton from './dropdown-button';

const HeaderLinks = ({
  disabled, titleClassName, reports, filter, sort, loading, dataHash,
  requestUrl = '/express/api/projects/database_report',
  requestKind = "get"
}) => {
  const startExport = (event, name, kind, reportTitle, formatTitle, pageEvent) => {
    const params = {
      name,
      report: name,
      report_format: kind,
      kind,
      filter,
      sort,
      report_title: reportTitle,
      format_title: formatTitle,
      platform: userDownloadCCPlatform
    };
    if (dataHash) {
      params.current_all_as_hash = dataHash;
    }
    const options = { requestKind };
    runExport(
      event,
      requestUrl,
      params,
      options
    );
    if (pageEvent) {
      vccPageEvent(pageEvent.kind, pageEvent.page, pageEvent.caseIds, formatTitle);
    }
  };

  return (
    <DropdownButton
      disabled={ disabled }
      listClassName="-to-right-bottom"
      buttonName="Export"
      loading={ loading }
    >
      <>
        {
          map(reports, (item, index) => (
            <li key={ index }>
              <span className={ titleClassName }>{ item.title }</span>
              <span>
                {
                  map(reportKindsArray(item), (kind, kindIndex) => (
                    <a
                      key={ `${index}${kindIndex}` }
                      className={ cn("-export", classByReportKind(item, kindIndex)) }
                      onClick={
                        (event) => {
                          startExport(
                            event,
                            item.name,
                            kindByIndex(item, kindIndex),
                            item.reportTitle || item.title,
                            reportKindTitle(item, kindIndex),
                            item.pageEvent
                          );
                        }
                      }
                    >
                      { reportKindTitle(item, kindIndex) }
                    </a>
                  ))
                }
              </span>
            </li>
          ))
        }
      </>
    </DropdownButton>
  );
};

export default HeaderLinks;
