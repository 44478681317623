import { filter, map, includes, flatten, each, find } from 'lodash';

const nameRegToReplace = /[^A-Za-z0-9*$\s,"'()\-_@!.~?/><[\]|}{^\\]/g;
const defaultHighLevelLogic = 'AND';
const highLevelSign = '$';
const lowLevelSign = '*';

// 'men$AND$age_1829*OR*age_3044' $ - separator for filter group * - separator for logic operator within group
const filtersFromStringToStructure = (initString) => {
  const preparedString = initString.replace(/\s+/g, '');
  return map(
    preparedString.split(highLevelSign),
    (item) => {
      if (includes([ "AND", "OR" ], item)) {
        return { kind: 'logic', value: item };
      }
      const innerValues = filter(
        map(
          item.split(lowLevelSign),
          (el) => {
            if (includes([ "AND", "OR" ], el)) {
              return { kind: 'logic', value: el };
            }
            return { kind: 'filter', value: el };
          }
        ),
        (item) => (!!item.value)
      );
      const defaultLogic = find(
        innerValues, (item) => (item.kind === 'logic')
      )?.value || 'OR';

      return {
        kind: 'complex',
        defaultLogic,
        logicList: [ 'AND', 'OR' ],
        values: innerValues
      };
    }
  );
};

// [
//   { kind: 'complex', values: [{ kind: 'filter', value: 'men' }] },
//   { kind: 'logic', value: 'AND' },
//   {
//     kind: 'complex',
//     values: [
//       { kind: 'filter', value: 'age_1829' },
//       { kind: 'logic', value: 'OR' },
//       { kind: 'filter', value: 'age_3044' }
//     ]
//   }
// ]
const filtersFromStructureToString = (structureValues, inner) => (
  flatten(
    map(
      structureValues,
      (item) => {
        if (item.kind === 'logic') {
          return (
            inner ?
              `${lowLevelSign}${item.value}${lowLevelSign}` :
              `${highLevelSign}${item.value}${highLevelSign}`
          );
        } else if (item.kind === 'filter') {
          return item.value;
        }
        return filtersFromStructureToString(item.values, true);
      }
    )
  ).join('')
);

const filterNamesFromStructure = (structureValues) => (
  flatten(
    map(
      structureValues,
      (item) => {
        if (item.kind === 'filter') {
          return item.value;
        }
        return filterNamesFromStructure(item.values);
      }
    )
  )
);

const parameterFromUrl = (pName, defaultValue) => {
  const query = new URLSearchParams(document.location.search);
  return query.get(pName) || defaultValue;
};

const generateParametersAsString = ({ columns, selectedTab, selectedBsg }) => {
  const params = [];
  if (selectedTab) {
    params.push(`selected_tab=${selectedTab}`);
  }
  if (selectedBsg) {
    params.push(`selected_bsg=${selectedBsg}`);
  }
  each(columns || [], (item) => {
    params.push(`columns[][name]=${item.name}&columns[][value]=${item.value}`);
  });
  return params.join('&');
};

export {
  filtersFromStringToStructure, filtersFromStructureToString,
  parameterFromUrl, generateParametersAsString, defaultHighLevelLogic,
  nameRegToReplace, filterNamesFromStructure
};
