import React, { useEffect, useState } from 'react';
import { map, find, uniq, compact, each, remove, filter, flatten, isEmpty, includes } from 'lodash';
import classnames from 'classnames';
import DropdownList from '../common/dropdown_list.js';
import { reorderQuestions } from "../common/custom_questions/helper";
import QuestionsOrderModal from "../common/questions-order-modal";
import QuotaModeGlobalState from '../common/quotaMode/components/quotaModeGlobalState';
import {
  optionalFromInitToDatabaseQuestion,
  initExistingOptionalQuestions,
  initExistingBaseDemoQuestions
} from './logic/questions_transition.js';

import ElementWrapper from './components/element_wrapper.js';
import { ListContext } from './contexts/list_context.js';
import { ConditionContext, conditionIs } from './contexts/condition_context.js';

const App = ({ data, options }) => {
  const {
    demographic_questions,
    optional_demographic_questions,
    multilang
  } = data;

  const {
    base_demographics_hash,
    optional_demographics_hash,
    default_language_code,
    country_language_mapping,
    language_code_mapping,
    country_notice,
    quota_disallowed_options,
    not_available_label,
    cant_stand_label
  } = options;
  const projectLaunched = options.project_launched;

  const [ baseInitialized, setBaseInitialized ] = useState(false);
  const [ optionalInitialized, setOptionalInitialized ] = useState(false);
  const [ questionsOrderData, setQuestionsOrderData ] = useState(null);

  const initialBaseDemo = () => {
    if (baseInitialized) {
      return [];
    }
    setBaseInitialized(true);
    return initExistingBaseDemoQuestions(
      demographic_questions,
      base_demographics_hash
    );
  };
  const initialOptionalDemo = () => {
    if (optionalInitialized) {
      return [];
    }
    setOptionalInitialized(true);
    return initExistingOptionalQuestions(
      optional_demographic_questions,
      optional_demographics_hash
    );
  };

  const [ baseDemo ] = useState(initialBaseDemo());
  const [ optionalDemo, updateOptionalDemo ] = useState(initialOptionalDemo());
  useEffect(() => {
    const body = document.body || document.querySelector('body');
    const styles =
      [
        ...(body.getAttribute('style') || '').split(';'),
        ...[ 'overflow: hidden' ]
      ].join(';');
    body.setAttribute('style', styles);
  }, []);

  const selectedCountry = () => {
    const countryQ = find(baseDemo, (el) => el.title === 'Country');
    if (!countryQ) {
      return "United States";
    }
    const countryId = compact(
      uniq(
        map(countryQ.condition, (v, k) => {
          return v === 'is' ? k : null;
        })
      )
    )[0];

    return countryQ.answers[`${countryId}`];
  };

  const setAvailableFlagForOptionalQuestions = () => {
    const questionsForCountry = map(
      [
        ...(optional_demographics_hash['All'] || []),
        ...(optional_demographics_hash[selectedCountry()] || [])
      ],
      (item) => item
    );
    each(optionalDemo, (q) => {
      q.available = !!find(
        questionsForCountry,
        (item) => item.title === q.title
      );
    });
  };

  const optionalDemographicsAvailableList = () => {
    const list = [];
    const currentList = filter(optionalDemo, (item) => !item._destroy);
    const cantStandTitlesList = flatten(
      map(currentList, (item) => item.cantStand)
    );

    const initializedAvailableQuestions = initExistingOptionalQuestions(
      [
        ...(optional_demographics_hash['All'] || []),
        ...(optional_demographics_hash[selectedCountry()] || [])
      ],
      optional_demographics_hash
    );

    each(
      initializedAvailableQuestions,
      (el) => {
        if (!find(currentList, (item) => item.title === el.title)) {
          list.push(el);
          el.disabled = !!find(cantStandTitlesList, (title) => title === el.title);
        }
      });
    return list;
  };

  const onOptionalItemSelect = (event, item) => {
    optionalDemo.push(optionalFromInitToDatabaseQuestion(item, conditionIs));
    updateOptionalDemo([ ...optionalDemo ]);
  };
  const onCountryChange = () => {
    setAvailableFlagForOptionalQuestions();
    updateOptionalDemo([ ...optionalDemo ]);
  };

  const onDelete = (event, question) => {
    event.preventDefault();
    if (question.id) {
      question['_destroy'] = true;
    } else {
      remove(optionalDemo, question);
    }
    reorderQuestions(optionalDemo);
    updateOptionalDemo([ ...optionalDemo ]);
  };

  const readOnly = (q) => (multilang && q.is_country);
  const filteredOptionalDemo = filter(optionalDemo, (item) => !item._destroy);
  const onOpenQuestionsOrderModal = (event) => {
    event.preventDefault();
    setQuestionsOrderData(optionalDemo);
  };

  const baseContext = {
    defaultLanguageCode: default_language_code,
    languageMapping: country_language_mapping,
    languageCodeMapping: language_code_mapping,
    tooltip: (question) => (question.is_country ? country_notice : null),
    allLabel: (question) => options[question.title] || 'All',
    onCountryChange: (question) => {
      return question.is_country
        ? () => {
          onCountryChange();
        }
        : null;
    }
  };

  const valueContextBase = { ...baseContext };
  valueContextBase.list = baseDemo;
  valueContextBase.quotaDisallowedOptions = quota_disallowed_options;
  valueContextBase.relationName = 'demographic_questions';
  valueContextBase.onDelete = null;
  valueContextBase.headerClassName = baseDemo.length === 2 ? "-width-full" : null;
  valueContextBase.notAvailableLabel = null;
  valueContextBase.cantStandText = () => null;

  const valueContextOptional = { ...baseContext };
  valueContextOptional.list = optionalDemo;
  valueContextOptional.quotaDisallowedOptions = {};
  valueContextOptional.relationName = 'optional_demographic_questions';
  valueContextOptional.onDelete = onDelete;
  valueContextOptional.headerClassName = '-width-full -relative';
  valueContextOptional.notAvailableLabel = not_available_label;
  valueContextOptional.cantStandText = (question) => {
    if (!question.cantStand.length) {
      return null;
    }
    const foundEl = find(
      optionalDemo,
      (el) => (!el._destroy && includes(question.cantStand, el.title))
    );
    if (!foundEl) {
      return null;
    }
    return cant_stand_label.replace('%title%', foundEl.title);
  };

  return (
    <ConditionContext.Provider value={ conditionIs }>
      <QuotaModeGlobalState questions={ baseDemo } options={ options }>
        <h3 className="form_section-title">Demographics</h3>
        <div className="form-group">
          <ListContext.Provider value={ valueContextBase }>
            <div className={ classnames("demographics form_grid -plates -tile", { "-cols-2": baseDemo.length > 2 }) }>
              {
                map(baseDemo, (q, index) => (
                  <ElementWrapper
                    key={ `demoQuestion-${index}` }
                    readOnly={ readOnly(q) }
                    question={ q }
                  />
                ))
              }
            </div>
          </ListContext.Provider>
        </div>
        {!isEmpty(optional_demographics_hash) &&
          <div className="optional-demographics">
            <div className="form-block-with-button">
              <div className="form-title-with-link">
                <h3 className="form_section-title">Additional Targeting Criteria</h3>
                <DropdownList
                  className=""
                  popupClassName=""
                  linkTitle={ 'Add Question' }
                  list={ optionalDemographicsAvailableList() }
                  listField={ 'uiTitle' }
                  onItemClick={ (event, item) => {
                    onOptionalItemSelect(event, item);
                  } }
                />
              </div>
              {
                filteredOptionalDemo.length > 1 && !projectLaunched &&
                <div className="form_section-title-item -order-button">
                  {
                    <button
                      className="button -secondary -white-back -blue"
                      onClick={ onOpenQuestionsOrderModal }
                    >
                      Change order
                    </button>
                  }
                </div>
              }
            </div>
            <div className="form-group">
              <ListContext.Provider value={ valueContextOptional }>
                <div className="form_grid -plates -tile">
                  {
                    map(optionalDemo, (question, index) => (
                      <ElementWrapper
                        key={ `optionalDemoQuestion-${index}` }
                        question={ question }
                      />
                    ))
                  }
                </div>
                {
                  !!questionsOrderData &&
                  <QuestionsOrderModal
                    questions={ questionsOrderData }
                    setQuestionsOrderData={ setQuestionsOrderData }
                    onSave={ updateOptionalDemo }
                    title="Optional Demographics - Change Order"
                    demo
                  />
                }
              </ListContext.Provider>
            </div>
          </div>
        }
      </QuotaModeGlobalState>
    </ConditionContext.Provider>
  );
};

export default App;
