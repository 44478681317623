import React, { useContext } from 'react';
import { IndexData } from './contexts';
import Block from './block';

const Logins = () => {
  const { data: { logins } } = useContext(IndexData);

  return (
    <>
      <h2 className="comparisons_section-title">
        Logins
      </h2>
      <div className="comparisons_plates">
        <div className="comparisons_plates-grid">
          <Block header="Total Logins" value={ logins.total } hint="Total number of logins across all users." />
          <Block header="Unique Logins" value={ logins.uniq } hint="Number of distinct users who have logged in." />
          <Block header="Logins Per Day" value={ logins.per_day } hint="Average number of logins per day." />
          <Block header="Time Between Logins" value={ logins.time_between } hint="Avg number of days between first and last login." />
        </div>
      </div>
    </>
  );
};

export default Logins;
