import React, { useContext } from 'react';
import { map } from "lodash";
import { IndexData } from './contexts';

const Downloads = () => {
  const { data: { downloads, downloads_table } } = useContext(IndexData);

  const columns = downloads_table.columns;

  const rows = downloads_table.rows;

  return (
    <>
      <h2 className="comparisons_section-title">
        Downloads
      </h2>

      <table className="table">
        <tbody>
          <tr>
            <th className="th th-section">Pages</th>
            <th className="th th-section -right">Total</th>

            {map(columns, (column) => (
              <th className="th th-section -right" key={ column[0] }>{column[1]}</th>
            ))}
          </tr>

          {map(rows, (row) => (
            <tr key={ row[0] }>
              <th className="th"><b>{row[1]}</b></th>
              <th className="col-data -right">{downloads[ row[0] ].total}</th>
              {map(columns, (column) => {
                const rec = downloads[ row[0] ][ column[0] ];
                return (
                  <th className="col-data -right" key={ column[0] }>
                    {rec.show && <span>{rec.value}</span>}
                    {!rec.show && <span>X</span>}
                  </th>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Downloads;
